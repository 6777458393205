import React from "react";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import Theme from "../Theme";

const styles = () => ({
  button: {
    color: Theme.palette.primary.light
  },
  total: {
    color: Theme.palette.primary.light
  }
});

const enhance = compose(withStyles(styles));
export default enhance(
  ({ page, perPage, total, setPage, ids, classes, ...rest }) => {
    const [first, setFirst] = React.useState(null);
    const [start, setStart] = React.useState(true);
    const [more, setMore] = React.useState(false);

    React.useEffect(() => {
      if (ids) {
        if (!first) {
          setFirst(ids[0]);
        } else {
          if (total <= 100) {
            setStart(true);
          } else if (total > 0 && ids[0] !== first) {
            setStart(false);
          } else {
            setStart(true);
          }
        }
      }
    }, [ids, start, first, setFirst, total]);
    React.useEffect(() => {
      if (ids && ids.length > 0) {
        setMore(ids.length >= 100);
        if (first && first === ids[0] && ids.length < 100) {
          setMore(false);
        }
      }
    }, [first, start, ids]);
    return (
      <Toolbar>
        {!start && (
          <Button
            className={classes.button}
            key="prev"
            icon={<ChevronLeft />}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </Button>
        )}
        {more && (
          <Button
            className={classes.button}
            key="next"
            icon={<ChevronRight />}
            onClick={() => setPage(page + 1)}
            labelposition="before"
          >
            Next
          </Button>
        )}
        <span className={classes.total}>
          {" "}
          Total: {total}
          {more ? "+" : ""}{" "}
        </span>
      </Toolbar>
    );
  }
);
