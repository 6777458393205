import React from 'react'
import ReactPlayer from 'react-player';

export default ({ playerRef, track, playing, onEnded, onPlay, onBuffer, onError }) => {
    return <ReactPlayer
        width={0}
        height={0}
        playsinline
        ref={playerRef}
        url={track}
        playing={playing}
        onPlay={() => onPlay && onPlay()}
        onBuffer={() => onBuffer && onBuffer()}
        onError={(...props) => onError && onError(...props)}
        onEnded={() => onEnded && onEnded()}
    />
}