
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting, } from "redux-form";
import {
    ReferenceArrayInput, SimpleForm, Edit, FormDataConsumer, ReferenceInput, CREATE, TextInput, SelectInput, SaveButton
} from 'react-admin';
import FeaturedItemsList from '../../Input/FeaturedItemsList';
import { Grid, Typography } from '@material-ui/core';
import ReorderableSection from './ReorderableSection';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    fetchEnd,
    fetchStart,
    required,
    withDataProvider,
    showNotification, Mutation, Button
} from 'react-admin';
import Remove from '@material-ui/icons/Remove'
import Save from '@material-ui/icons/Save'
import IconContentAdd from "@material-ui/icons/Add";
// import IconDragHandle from "@material-ui/icons/DragHandle";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { compose } from "recompose";
import StatusInput from "../../Input/StatusInput";
import { entityChoices } from "./Constants";

const mapStateToProps = state => ({
    isSubmitting: isSubmitting("section-quick-create")(state),
});
const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
};


export const AddSectionModal = compose(
    withDataProvider,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(({ isSubmitting, defaultShowDialog, submit, onAddSection,
    dataProvider, fetchStart, fetchEnd, showNotification }) => {
    const [showDialog, setShowDialog] = React.useState(defaultShowDialog || false)
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSaveClick = () => {
        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit("section-quick-create");
    };
    const handleSelectClick = () => {
        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit("section-select");
    };

    const handleSubmit = values => {
        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();
        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        dataProvider(CREATE, "featured", { data: values })
            .then(({ data }) => {
                // Update the main react-admin form (in this case, the comments creation form)
                // change(REDUX_FORM_NAME, "post_id", data.id);
                onAddSection(data.id)
                setShowDialog(false);
            })
            .catch(error => {
                showNotification(error.message, "error");
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
            });
    };
    const handleSelect = item => {
        // console.log(item, record)
        onAddSection(item.section)
        // change(REDUX_FORM_NAME, "sections", [record.section, ...record.sections]);
        setShowDialog(false);
    };
    return (
        <Fragment>
            <Button onClick={handleClick} label="Add a section">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Add a section"
            >
                <DialogTitle>Add a Section</DialogTitle>
                <DialogContent>
                    <React.Fragment>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="section-select"
                            resource="featured"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={handleSelect}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <ReferenceInput
                                label={`Select section to add`} source={"section"} reference={"featured"}>
                                <SelectInput optionText={(choice) => {
                                    return choice.title || choice.name
                                }} />
                            </ReferenceInput>
                            <SaveButton label="Add Section" onClick={handleSelectClick} />
                        </SimpleForm>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="section-quick-create"
                            resource="featured"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <Typography variant="title">Add a new Section</Typography>
                            <TextInput source="name" validate={required()} />
                            <TextInput source="title" validate={required()} />
                            <SelectInput source="type" choices={[{ id: "items", name: "A list of content" },
                            { id: "query", name: "Search for content" }]} />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData.type === "items") return <SelectInput
                                        source="target"
                                        choices={entityChoices}
                                        {...rest}
                                    />
                                    return <div />
                                }
                                }
                            </FormDataConsumer>
                            <StatusInput />
                        </SimpleForm>
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <SaveButton saving={isSubmitting || false} onClick={handleSaveClick} />
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
})

export const ScreenTitle = ({ record, create }) => {
    return <span>{create ? "New Screen" : `"Edit Screen - "${record ? record.name : ''}`}</span>;
};

export const TargetReferenceArrayInput = (props) =>
    <ReferenceArrayInput  {...props} source="items" reference={props.record.target} label={props.record.target}>
        <FeaturedItemsList optionText="name" />
    </ReferenceArrayInput>
const grid = 10
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#000' : '#424242',
    display: 'flex',
    flexDirection: "column",
    padding: grid,
    overflow: 'auto',
    borderRadius: 10
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    borderRadius: 10,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '#424242',

    // styles we need to apply on draggables
    ...draggableStyle,
});
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ApproveButton = ({ record, resource, sections }) => {
    return <Mutation
        type="UPDATE"
        resource={resource}
        payload={{ id: record.id, data: { sections }, previousData: record }}
        options={{
            undoable: false,
            onSuccess: {
                notification: { body: `Screen section order has been updated`, level: "success" },
                refresh: false
            },
            onError: {
                notification: { body: `Error: Screen section order was not updated`, level: "warning" },
                refresh: false
            },
            refresh: false,
            callback: (props) => {
                if (!props.error) {
                    // done(sections)
                }
            }
        }}
    >
        {approve => <Button label={`Save Screen`} onClick={approve}>
            <Save />
        </Button>}
    </Mutation>
}

export const SectionLevels = ({ record, ...rest }) => {
    const [state, setState] = React.useState({ items: record.sections, dragEnd: true })
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        );
        // this.props.input.onChange(items.map(v => v.id))
        setState({
            items,
            dragEnd: true
        });
    }

    const handleRemove = (section, index) => {
        var ix = state.items.indexOf(section);

        if (ix > -1) {
            const items = state.items
            items.splice(ix, 1)
            setState({ ...state, items })
        }
    }

    return <Grid container xs={12} direction="column" alignItems="flex-start" justify="flex-start">
        <Grid direction="row">
            <AddSectionModal {...rest} record={record} onAddSection={(sectionID) => {
                setState({
                    items: [sectionID, ...(state.items ? state.items : [])],
                    dragEnd: state.dragEnd
                })
            }} />
            <ApproveButton record={record} resource={"screens"}
                sections={state.items}
                done={(updated) => {
                    setState({
                        items: updated,
                        dragEnd: true
                    });
                }} />
        </Grid>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {state.items && state.items.map((section, index) => <Draggable key={`${section}-${index}`} draggableId={section} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <Grid direction="row" container spacing={24}>
                                        {/* <Grid item xs={1}><IconDragHandle /></Grid> */}
                                        <Button onClick={() => handleRemove(section, index)} label="Remove section">
                                            <Remove />
                                        </Button>
                                        <Grid item xs={12} direction="column"
                                        >
                                            <ReorderableSection sectionID={section} />
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Draggable>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </Grid>
}

export default props => (
    <Edit {...props} title={<ScreenTitle />} actions={<div />}>
        <SimpleForm toolbar={<div />}>
            <SectionLevels />
        </SimpleForm>
    </Edit>
)