import React from "react";
import { SimpleForm, Create } from "react-admin";
import { EditSections } from "./Form";
export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="/banners">
        <EditSections />
      </SimpleForm>
    </Create>
  );
};
