import React, { useState, useRef, useEffect } from "react";
import {
  Filter,
  ReferenceInput,
  TextInput,
  ImageField,
  ReferenceField,
  SelectInput,
  TextField,
  Datagrid,
  EditButton,
  List,
  DateField,
  BooleanInput,
  CardActions,
  ChipField,
  RefreshButton,
  NumberField,
} from "react-admin";
import Pagination from "../../Pagination";
import Player from "../../Components/Player";
import PlayerButton from "../../Buttons/PlayerButton";
import DurationField from "../../Fields/DurationField";
import { AutocompleteInput } from "ra-ui-materialui/lib/input";
import { DateInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
// import Modal  from "../../Components/Modal";
import { connect } from "react-redux";
import { usePlayer } from "../../Context/PlayerProvider";
import CopyToClipboardField from "../../Fields/CopyToClipboardField";

const choices = [
  { _id: "active", name: "Active" },
  { _id: "inactive", name: "InActive" },
  { _id: "error", name: "Error" },
];

const dateFormatter = (v) => {
  // console.log(v)
  if (!(v instanceof Date) || isNaN(v)) return new Date(v);
  return v;
};

const dateParser = (v) => {
  const d = new Date(v);
  if (isNaN(d)) return;
  return d.getTime();
};

const TracksFilter = (props) => {
  // console.log("PROPS TO TRACKS FILTER", props);
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <BooleanInput source="isPromo" label="Share and Win" allowEmpty />
      <TextInput label="Name" source="name" />
      <ReferenceInput
        label="Album"
        source="album"
        reference="albums"
        filterToQuery={(searchText) => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optiontext="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Artist"
        source="artist"
        reference="artists"
        filterToQuery={(searchText) => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optiontext="name" />
      </ReferenceInput>
      <TextInput label="Genre" source="genre_name" allowEmpty />
      <SelectInput
        source="status"
        choices={choices}
        optionText="name"
        optionValue="_id"
      />
      <ReferenceInput label="CP" source="cp" reference="cp" allowEmpty>
        <SelectInput optiontext="name" />
      </ReferenceInput>
      <TextInput label="UPC" source="upc" allowEmpty />
      <DateInput
        label="Release Date"
        source="releasedate"
        defaultValue={new Date()}
        parse={dateParser}
        format={dateFormatter}
        options={{ format: "DD/MM/YYYY" }}
        providerOptions={{ utils: MomentUtils }}
      />
      <DateInput
        label="Created Date"
        source="createdDate"
        defaultValue={new Date()}
        parse={dateParser}
        format={dateFormatter}
        options={{ format: "DD/MM/YYYY" }}
        providerOptions={{ utils: MomentUtils }}
      />
      {/* <DateTimeInput label="Release Date" source="releasedate" format={dateFormatter} providerOptions={{ utils: MomentUtils }}  /> */}
      {/* <TextInput label="Genre" source="genre" /> */}
    </Filter>
  );
};

const TracksActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {/* <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    /> */}
    <RefreshButton />
  </CardActions>
);

export const TrackDataGrid = (props) => {
  // console.log("THE PLAYLIST", props);
  let [currentTrack, setCurrentTrack] = useState(null);
  let [playing, setPlaying] = useState(false);
  let [loading, setLoading] = useState(false);
  let [track, setTrack] = useState(null);
  const player = usePlayer();

  useEffect(() => {
    if (!currentTrack) return;
    //fetch track and setTrack and play
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-api-key": process.env.REACT_APP_UDUX_API_KEY,
    });
    setPlaying(false);
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_UDUX_API_URL}/tracks/byId/` + currentTrack.id,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        if (data.source) {
          setTrack(data.source);
          setPlaying(true);
          // setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false);
        setPlaying(false);
        setCurrentTrack(null);
        // console.log(error)
      });
  }, [currentTrack]);

  const playerRef = useRef(null);
  return (
    <React.Fragment>
      <Player
        playerRef={playerRef}
        currentTrack={currentTrack}
        playing={playing}
        track={track}
        onPlay={() => {
          if (loading) setLoading(false);
        }}
        onEnded={() => setPlaying(false)}
        loading={loading}
      />
      <Datagrid {...props}>
        <CopyToClipboardField field="sharelink" />
        <TextField source="streams" label="Streams" />
        <TextField source="android" label="Android" />
        <TextField source="ios" label="iOS" />
        <TextField source="web" label="Web" />
        <ImageField
          className="size"
          source="artwork"
          title={"artwork for item"}
        />
        <TextField source="upc" label="UPC" />
        <NumberField source="album_index" label="Album Index" />
        <TextField source="name" label="Track Name" />
        <ReferenceField source="artist" reference="artists" label="Artist Name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="artist_name" label="Track Artist Name" />
        <ReferenceField source="album" reference="albums" label="Album Name">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Genres" source="genre" reference="genres">
          <TextField source="name" />
        </ReferenceField>
        <DurationField />
        <DateField source="releasedate" label="Release Date" showTime />
        <DateField source="created" label="Created" showTime />
        <ReferenceField label="CP" source="cp" reference="cp">
          <TextField source="name" />
        </ReferenceField>
        <ChipField source="status" />
        <PlayerButton
          onClick={(record) => {
            player.setQueue([record]);
            onPlay(
              currentTrack,
              record,
              playing,
              loading,
              setPlaying,
              setCurrentTrack
            );
          }}
          loading={loading}
          playing={playing}
          currentTrack={currentTrack}
        />
        <EditButton />
      </Datagrid>
    </React.Fragment>
  );
};

const onPlay = (
  currentTrack,
  record,
  playing,
  loading,
  setPlaying,
  setCurrentTrack
) => {
  if (!loading && record && currentTrack && record.id === currentTrack.id) {
    setPlaying(!playing);
    return;
  }
  // console.log("changeTrack", record)
  setCurrentTrack(record);
};

const TrackList = (props) => {
  return (
    <List
      filters={<TracksFilter />}
      pagination={<Pagination />}
      actions={<TracksActions />}
      perPage={200}
      {...props}
    >
      <TrackDataGrid loading={props.progress} />
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    progress: state.UploadReducer.progress,
    message: state.UploadReducer.data,
  };
};

export default connect(mapStateToProps)(TrackList);
