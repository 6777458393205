import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  ChipField,
} from "react-admin";
import Pagination from "../../Pagination";

export default (props) => {
  return (
    <List title="Banners" pagination={<Pagination />} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="subtitle" />
        <ChipField source="status" />
        <ImageField className="size" source="mobile_artwork" title="Artwork" />
        <ImageField
          className="size"
          source="desktop_artwork"
          title="Mobile Artwork"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
