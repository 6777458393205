import React from "react";
import {
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceField,
} from "react-admin";
import QuerySection from "../Resource/Screens/QuerySection";
// import ItemsList from "../Resource/Screens/ItemsList";
// <ItemsList record={props.record} mini {...props} />

export const TargetReferenceArrayField = (props) => {
  if (props.record.type === "items") {
    return (
      <ReferenceArrayField
        {...props}
        label={props.record.target}
        reference={props.record.target}
        source={props.targetField}
      >
        <SingleFieldList>
          <ReferenceField
            label="Author"
            source="id"
            reference={props.record.target}
          >
            {props.record.target === "videos" ? (
              <ImageField
                source="pictures.sizes.2.link"
                title="title"
                className="size"
              />
            ) : (
              <ImageField className="size" source="artwork" title="artwork" />
            )}
          </ReferenceField>
        </SingleFieldList>
      </ReferenceArrayField>
    );
  }

  if (props.record.type === "query") {
    return <QuerySection record={props.record} mini {...props} />;
  }
  return <span>{props.record.type}</span>;
};
