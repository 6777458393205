import React from 'react';
import { DateTimeInput } from 'react-admin-date-inputs';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

const dateFormatter = v => {
    console.log(v)
    if (!(v instanceof Date) || isNaN(v)) return new Date(v);
    return v
};

const dateParser = v => {
    const d = new Date(v);
    if (isNaN(d)) return;
    return d.getTime();
};

export default (props) =>
    <DateTimeInput {...props} format={dateFormatter} parse={dateParser} providerOptions={{ utils: MomentUtils }} />