import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// / a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#424242',

  // styles we need to apply on draggables
  ...draggableStyle,
});

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length)
    return false;
  for (var i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i])
      return false;
  }

  return true;
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#424242',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

export default class ReorderInput extends Component {
  static defaultProps = {
    direction: "horizontal"
  }
  static getDerivedStateFromProps(props, state) {
    let items = {}
    props.choices.forEach(v => items[v.id] = v)
    const choices = props.input.value ? props.input.value.map(v => items[v]).filter(v => typeof v != "undefined") : null
    const choicesId = choices ? choices.map(v => v.id) : [], currentIds = state.items ? state.items.map(v => v.id) : []
    if (
      (arraysEqual(choicesId, currentIds) || choicesId.length !== currentIds.length) && state.dragEnd === false
    ) {
      return {
        items: choices
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    let items = {}
    props.choices.forEach(v => items[v.id] = v)
    const choices = props.input.value ? props.input.value.map(v => items[v]).filter(v => typeof v != "undefined") : []
    this.state = {
      items: choices,
      dragEnd: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragStart = () => {
    this.setState({
      dragEnd: true,
    })
  }
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    this.props.input.onChange(items.map(v => v.id))
    this.setState({
      items,
      dragEnd: true
    });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
        <Droppable droppableId="droppable" direction={this.props.direction}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >

              {/* <div style={{
                marginRight: "30px",
                marginTop: "10px",
                width: 160, height: 160,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justify: "center",
                alignItems: "center"
              }}>
                <span>...</span>
              </div> */}
              {this.state.items && this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {this.props.renderItem(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}