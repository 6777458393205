import React, { Component } from 'react'
import compose from 'recompose/compose';
import {
    UPDATE,
    withDataProvider,
} from 'react-admin';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Theme from '../Theme';
import Icon from '@material-ui/icons/Send';

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        color: Theme.palette.primary.light
    },
};

class SendPush extends Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        // console.log(this.props)
        dataProvider(UPDATE, 'push', { id: record.id, data: { ...record, scheduledAt: '', scheduled: false, id: record.id } }, {
            undoable: false,
            onSuccess: {
                notification: { body: 'Push notification is going to be sent', level: 'info' },
                redirectTo: '/push',
            },
            onFailure: {
                notification: { body: 'Error: push notification not sent', level: 'warning' }
            }
        })
    }
    render() {
        const { classes } = this.props
        return <Button variant="flat" onClick={this.handleClick}>
            <Icon className={classes.icon} />
            Send Now
        </Button>
    }
}

SendPush.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object,
};

const enhance = compose(
    withStyles(styles),
    withDataProvider
);
export default enhance(SendPush);