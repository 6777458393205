import React, { useRef, useState, useEffect } from "react";
import { withDataProvider, GET_MANY, GET_ONE, Loading } from "react-admin";
import compose from "recompose/compose";
import { withStyles, Avatar, Grid, Button } from "@material-ui/core";
import Player from "./Player";
import PlayerButton from "../Buttons/PlayerButton";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import IconContentClose from "@material-ui/icons/RemoveCircle";
import Theme from "../Theme";
import AddItem from "../Input/AddItem";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    borderRadius: 10,
    backgroundColor: Theme.palette.primary.main,
  },
  image: { width: 50, height: 50, borderRadius: 5 },
  table: {
    minWidth: 700,
  },
  row: {
    padding: 10,
    borderRadius: 10,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  content: {
    backgroundColor: theme.palette.background.default,
  },
});

const onPlay = (
  currentTrack,
  record,
  playing,
  loading,
  setPlaying,
  setCurrentTrack
) => {
  if (!loading && record && currentTrack && record.id === currentTrack.id) {
    setPlaying(!playing);
    return;
  }
  setCurrentTrack(record);
};
const grid = 0;
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#000" : "#424242",
  display: "flex",
  flexDirection: "column",
  padding: 0,
  overflow: "auto",
  borderRadius: 10,
});
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 5 * 2,
  borderRadius: 10,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#424242",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const enhance = compose(withStyles(styles), withDataProvider);

export default enhance(
  ({ classes, input, contentResource, dataProvider, ...rest }) => {
    const allTracks = input.value || {};
    const trackIDS = Object.keys(allTracks);
    trackIDS.sort(function (a, b) {
      return allTracks[a] - allTracks[b];
    });
    const playerRef = useRef(null);
    let [currentTrack, setCurrentTrack] = useState(null);
    let [playing, setPlaying] = useState(false);
    let [playerLoading, setPlayerLoading] = useState(false);
    let [track, setTrack] = useState(null);
    const [initialItems] = React.useState(trackIDS);
    const [data, setData] = useState([]);
    const [state, setState] = React.useState({
      items: trackIDS,
      dragEnd: true,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [newItem, setNewItem] = useState(null);

    const onRemove = (item) => {
      const newItems = state.items.filter((value) => item.id !== value);
      setState({ items: newItems });
      const newItemsIndex = {};
      newItems.map((v, index) => (newItemsIndex[v] = index));
      setData(data.filter((value) => item.id !== value.id));
      input.onChange(newItemsIndex);
    };
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        state.items,
        result.source.index,
        result.destination.index
      );
      // this.props.input.onChange(items.map(v => v.id))
      setState({
        items,
        dragEnd: true,
      });
      setData(data.sort((a, b) => items.indexOf(a.id) - items.indexOf(b.id)));
      let newValues = {};
      items.forEach((v, index) => (newValues[v] = index));
      input.onChange(newValues);
    };

    useEffect(() => {
      if (!currentTrack) return;
      //fetch track and setTrack and play
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-api-key": process.env.REACT_APP_UDUX_API_KEY,
      });
      setPlaying(false);
      setPlayerLoading(true);
      fetch(
        `${process.env.REACT_APP_UDUX_API_URL}/tracks/byId/` + currentTrack.id,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 200) return resp.json();
        })
        .then((data) => {
          if (data.source) {
            setTrack(data.source);
            setPlaying(true);
            // setPlayerLoading(false)
          }
        })
        .catch((error) => {
          setPlayerLoading(false);
          setPlaying(false);
          setCurrentTrack(null);
          // console.log(error)
        });
    }, [currentTrack]);
    useEffect(() => {
      const fetchData = async () => {
        if (!initialItems) return;
        setIsError(false);
        setIsLoading(true);
        try {
          const result = await dataProvider(GET_MANY, contentResource, {
            ids: initialItems,
          });
          setData(result.data);
        } catch (error) {
          console.log(error);
          // setIsError(true);
        }
        setIsLoading(false);
      };
      fetchData();
    }, [dataProvider, contentResource, initialItems]);

    useEffect(() => {
      const fetchData = async () => {
        if (!newItem) {
          return;
        }
        try {
          const result = await dataProvider(GET_ONE, contentResource, {
            id: newItem,
          });
          const newData = [result.data, ...data];
          const ids = newData.map((v) => v.id);
          setData(newData);
          let newValues = {};
          setState({
            items: ids,
          });
          Object.values(ids).forEach((v, index) => (newValues[v] = index));
          console.log("changed input", newValues);
          input.onChange(newValues);
        } catch (error) {
          setIsError(true);
        }
        setNewItem(null);
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newItem]);
    const onSelected = (item) => {
      if (item) setNewItem(item);
    };
    // if (!input.value) return <div />;
    return (
      <React.Fragment>
        <Player
          playerRef={playerRef}
          currentTrack={currentTrack}
          playing={playing}
          track={track}
          onPlay={() => {
            if (playerLoading) setPlayerLoading(false);
          }}
          onEnded={() => setPlaying(false)}
          loading={playerLoading}
        />
        <Grid container direction="column">
          <Grid item>
            <AddItem
              target={contentResource}
              items={state.items}
              onSelected={onSelected}
              {...{ classes, input, contentResource, dataProvider, ...rest }}
            />
          </Grid>
          <Grid item>
            <OrderedList
              {...{
                onRemove,
                data,
                loading: isLoading,
                isError,
                input,
                currentTrack,
                playing,
                setPlaying,
                classes,
                onDragEnd,
                setCurrentTrack,
                track,
                setTrack,
                setPlayerLoading,
                playerLoading,
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);

const GridHeader = (props) => (
  <Typography
    variant="subheading"
    align="flex-start"
    style={{ color: "#000", marginLeft: "10px" }}
  >
    {props.text}
  </Typography>
);
const GridText = (props) => (
  <Typography align="flex-start">{props.text}</Typography>
);

const OrderedList = ({
  data,
  loading,
  isError,
  currentTrack,
  setPlaying,
  classes,
  onRemove,
  setCurrentTrack,
  playing,
  onDragEnd,
}) => {
  if (loading) return <Loading />;
  if (isError) return <p></p>;
  return (
    <Paper className={classes.root}>
      <Typography
        variant="subheading"
        style={{ color: "#000", margin: "15px" }}
      >
        {" "}
        {data.length} Total Tracks
      </Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={1}>
          <GridHeader text="Artwork" />
        </Grid>
        <Grid item xs={1}>
          <GridHeader text="Status" />
        </Grid>
        <Grid item xs={2}>
          <GridHeader text="Name" />
        </Grid>
        <Grid item xs={2}>
          <GridHeader text="Artist" />
        </Grid>
        <Grid item xs={2}>
          <GridHeader text="Genre" />
        </Grid>
        <Grid item xs={2}>
          <GridHeader text="Album" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => (
                <Draggable
                  key={`${item.id}-${index}`}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.row}
                      >
                        <Grid item xs={1}>
                          <Avatar
                            src={item["artwork"]}
                            className={classNames(classes.image)}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <GridText text={item.status} />
                        </Grid>
                        <Grid item xs={2}>
                          <GridText text={item.name} />
                        </Grid>
                        <Grid item xs={2}>
                          <GridText text={item.artist_name} />
                        </Grid>
                        <Grid item xs={2}>
                          <GridText text={item.genre_name} />
                        </Grid>
                        <Grid item xs={2}>
                          <GridText text={item.album_name} />
                        </Grid>
                        <Grid item xs={1}>
                          <PlayerButton
                            record={item}
                            iconProps={{ width: 30, height: 30 }}
                            onClick={() =>
                              onPlay(
                                currentTrack,
                                item,
                                playing,
                                loading,
                                setPlaying,
                                setCurrentTrack
                              )
                            }
                            loading={loading}
                            playing={playing}
                            currentTrack={currentTrack}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            className=""
                            onClick={() => onRemove(item)}
                            label=""
                            style={{}}
                          >
                            <IconContentClose color="red" />
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
};
