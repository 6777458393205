import React from 'react';
import { ReferenceArrayInput, SelectInput, TextInput, Create } from 'react-admin';
import { TabbedForm, FormTab } from 'react-admin'
import FeaturedItemsList from '../../Input/FeaturedItemsList';
import StatusInput from '../../Input/StatusInput';
import { entityChoices } from './Constants';

const FeaturedSectionTitle = ({ record }) => {
    return <span>Create Section</span>;
};

export const TargetReferenceArrayInput = (props) =>
    <ReferenceArrayInput {...props} source="items" reference={props.record.target} label={props.record.target}>
        <FeaturedItemsList optionText="name" />
    </ReferenceArrayInput>

export default props => (
    <Create {...props} title={<FeaturedSectionTitle />}>
        <TabbedForm>
            <FormTab label="Details">
                <TextInput source="name" />
                <TextInput source="title" />
                <SelectInput source="type" choices={[
                    { id: "items", name: "List of Content" },
                    { id: "query", name: "Search for Content" },
                ]} />
                <SelectInput source="target" choices={entityChoices} />
                <StatusInput label="Status" />
            </FormTab>
        </TabbedForm>
    </Create>
)