import React from 'react';
import {
    List, Datagrid, TextField, ReferenceField, ImageField, EditButton, RichTextField,
    ChipField, Show, TabbedShowLayout, Tab, BooleanField, ReferenceManyField
} from 'react-admin';
import { TextInput, Filter } from 'react-admin';
import TrackList from '../Track/TrackList';
import Pagination from '../../Pagination';
import RelatedTracks from '../../Buttons/RelatedTracks';
import LinkRelated from '../../Buttons/LinkRelated'

const ArtistsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="Name" source="name" allowEmpty />
        {/* <ReferenceInput label="Artist" source="artist" reference="artists" allowEmpty>
            <AutocompleteInput optiontext="name" />
        </ReferenceInput> */}
        {/* <TextInput label="Artist" source="name" allowEmpty /> */}
        <TextInput label="Genre" source="genres" allowEmpty />
        {/* a.to.Lowercase is not a function */}
        <TextInput label="Year" source="year" allowEmpty />
    </Filter>
);

export default props => (
    <List filters={<ArtistsFilter />} {...props} pagination={<Pagination />}>
        <Datagrid>
            {/* <TextField source="sharelink" /> */}
            <ImageField className="size" source="artwork" title="name" />
            <TextField source="name" />
            {/* <ReferenceField source="artist" reference="artists">
                <TextField source="name" />
            </ReferenceField> */}
            {/* <ChipField source="genres" /> */}
            {/* <TextField source="year" /> */}
            <ChipField source="status" />
            <RelatedTracks field="artist" />
            <LinkRelated field="artist" target="albums" label="Albums" />
            <EditButton label="Edit" />
        </Datagrid>
    </List>
);

export const ArtistsShow = (props) => (
    <Show filters={<ArtistsFilter />} {...props}>
        <TabbedShowLayout>
            <Tab label="MAIN INFO.">
                {/* <ImageField source="artwork" /> */}
                <TextField source="name" />
                <ReferenceField source="artist" reference="artists">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="description" />
                <ChipField source="genres" />
                <TextField source="year" />
                <RichTextField source="description" stripTags />
            </Tab>
            <Tab label="tracks" path="tracks">
                <ReferenceManyField reference="tracks" target="artist" addLabel={false}>
                    <TrackList />
                </ReferenceManyField>
            </Tab>
            <Tab label="MISC.">
                <TextField label="CP" />

                <ReferenceField label="Created" source="cp" reference="cp">
                    <TextField source="created" />
                </ReferenceField>
                <ReferenceField label="Modified" source="cp" reference="cp">
                    <BooleanField source="modified" />

                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);