import React from "react";

const PlayerContext = React.createContext({});

const { Provider, Consumer } = PlayerContext;

export const PlayerProvider = (props) => {
  const { children, audio } = props;
  const [queue, setQueue] = React.useState([]);
  const onSetQueue = (queue) => {
    console.log("appended");
    setQueue(queue);
  };
  React.useEffect(() => {
    const sourceless = queue.filter((v) => !v.source);
    if (sourceless === 0) return;
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-api-key": process.env.REACT_APP_UDUX_API_KEY,
    });
    for (let i = 0; i < queue.length; i++) {
      const currentTrack = queue[i];
      fetch(
        `${process.env.REACT_APP_UDUX_API_URL}/tracks/byId/` + currentTrack.id,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 200) return resp.json();
        })
        .then((data) => {
          if (data.source) {
            audio.appendAudio(0, [
              {
                ...currentTrack,
                musicSrc: data.source.replace("saudio", "umedia"),
              },
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [audio, queue]);
  return (
    <Provider
      value={{
        playing: false,
        setQueue: onSetQueue,
      }}
    >
      {children}
    </Provider>
  );
};

export const usePlayer = () => React.useContext(PlayerContext);
export default Consumer;
