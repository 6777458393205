import React from "react";
import { SelectInput } from "react-admin";

const choices = [
  { _id: "active", name: "Active" },
  { _id: "inactive", name: "InActive" },
  { _id: "error", name: "Error" },
  { _id: "deleted", name: "Deleted" },
];
export default (props) => {
  let { resource, record } = props;

  let choice = choices;
  if (record) {
    if (record.status !== "deleted" && record.status !== "error") {
      choice = choices
        .filter((v) => v.name !== "Error")
        .filter((v) => v.name !== "Deleted");
    }
  }

  if (resource === "tracks") {
    choices.push({ _id: "tracks-missing", name: "Track Missing" });
  }

  return (
    <SelectInput
      {...props}
      defaulValue="inactive"
      source="status"
      choices={choice}
      optionText="name"
      optionValue="_id"
    />
  );
};
