import React from 'react'
import { List, Datagrid, TextField, EditButton, EmailField, Create, TextInput, SimpleForm, Edit } from 'react-admin'
import StatusInput from '../../Input/StatusInput';
import Pagination from '../../Pagination';

export const CpList = props => (
    <List {...props} pagination={<Pagination />}>
        <Datagrid  >
            <TextField source="name" />
            <TextField source="country" />
            <EmailField source="email" />
            <TextField source="address" />
            <TextField source="website" />
            <TextField source="status" />
            <EditButton />
        </Datagrid>
    </List>
);

export const CpEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <TextInput source="name" />
            <TextInput source="country" />
            <StatusInput />
        </SimpleForm>
    </Edit>
);

export const CpCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/cp">
            <TextInput source="email" />
            <TextInput source="name" />
            <TextInput source="country" />
            <StatusInput />
        </SimpleForm>
    </Create>
);