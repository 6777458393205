import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { submit, isSubmitting } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import { selectTitle } from "../Libs/Contstants";

class AddItem extends Component {
  state = {
    error: false,
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit("select-content");
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {};
  handleSubmit = (values) => {
    const { onSelected, showNotification, items, target } = this.props;
    const field = target.substring(0, target.length - 1);
    const selectedValue = values[field];
    if (selectedValue) {
      if (items && items.includes(selectedValue)) {
        showNotification("This item already exists in the list");
        return;
      }
      onSelected(selectedValue);
    } else {
      showNotification("Please select " + selectTitle[target], "info");
    }
  };
  queryForTarget = (target, searchText) => {
    switch (target) {
      case "playlists":
        return { name: searchText, public: true };

      default:
        return { name: searchText };
    }
  };
  render() {
    const { target } = this.props;
    const field = target.substring(0, target.length - 1);
    return (
      <Fragment>
        <div>
          <SimpleForm
            form="select-content"
            resource={target}
            // We override the redux-form onSubmit prop to handle the submission ourselves
            onSubmit={this.handleSubmit}
            // We want no toolbar at all as we have our modal actions
            toolbar={null}
          >
            <ReferenceInput
              key={target}
              filterToQuery={(searchText) => ({ q: searchText })}
              label={`Select ${selectTitle[target]} to add`}
              source={field}
              reference={target}
            >
              <AutocompleteInput
                optionText={(choice) => {
                  if (choice.album_name) {
                    // console.log("CHOICE IN PLAYLIST", choice);
                    return `${choice.artist_name} - ${choice.album_name} - ${choice.name} - ${choice.status} status`;
                  } else if (choice.artist_name) {
                    return `${choice.artist_name} - ${choice.name}  - ${choice.status} status`;
                  }
                  return `${choice.artist_name || ""} - ${
                    choice.album_name || ""
                  } - ${
                    choice.name || choice.title || choice.id || "unknown"
                  }  - ${choice.status} status`;
                }}
              />
            </ReferenceInput>
            <Button onClick={this.handleClick} label="Add Selected">
              <IconContentAdd />
            </Button>
          </SimpleForm>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting("select-content")(state),
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
