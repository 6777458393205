import React, { useState } from 'react';
import {
    TextField, ImageField, Show, TabbedShowLayout, Tab, ReferenceManyField
} from 'react-admin';
import TrackList from '../Track/TrackList';
import ReactPlayer from 'react-player';


const ArtistsTitle = ({ record }) => {
    return <span>Artists {record ? `"${record.title}"` : ''}</span>;
};

export default (props) => {
    let [currentTrack] = useState(null)
    return (
        <Show title={<ArtistsTitle />} {...props}>
            <ReactPlayer
                width={0}
                height={0}
                playsinline
                url={currentTrack}
                playing={false}
                onEnded={() => this.setState({ playing: false })}
            />
            <TabbedShowLayout>
                <Tab label="Details">
                    <ImageField source="artwork" />
                    <TextField source="name" />
                </Tab>
                <Tab label="tracks" path="tracks">
                    <ReferenceManyField reference="tracks" target="artist" addLabel={false}>
                        <TrackList onPlay={(record) => console.log(record)} />
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};