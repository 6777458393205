import React, { useState, useEffect } from "react";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import firebase from "firebase";
import Theme from "../../Theme";
import compose from "recompose/compose";
import { withStyles, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import PlayerButton from "../../Buttons/PlayerButton";
import { TrackPlayer } from "./TrackPlayer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
    overflowX: "auto",
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
  },
});

const enhance = compose(
  withStyles(styles),
  connect(() => {
    return {};
  })
);
export const TrackUploader = enhance(({ record, classes }) => {
  const [playing, setPlaying] = useState(false);
  const [transcoding, setTranscoding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [track, setTrack] = useState(null);
  const [trackID, setTrackID] = useState(record.id);
  const user = firebase.auth().currentUser;
  const audioPath = `cp/audio/${user.uid}`;
  const transcodeAudioPath = `cp-audio-${user.uid}`;
  const handleUploadStart = () => {
    setUploading(true);
    setProgress(0);
  };
  const handleProgress = (progress) => {
    setProgress(progress);
  };
  const handleUploadError = (error) => {
    setUploading(false);
    console.error("upload error", error);
  };

  const handleUploadSuccess = async (filename) => {
    // transcode item
    setTrackID(null);
    setTrack(null);
    setTranscoding(true);
    setPlaying(false);
    try {
      const resp = await fetch(
        `http://35.223.1.47/transcode/udux-next/udux-next.appspot.com/${transcodeAudioPath}/${filename}/${record.id}`
      );
      console.log("response", resp);
    } catch (error) {
      console.error("error transcoding file", error);
    }
    setTrackID(record.id);
    setTranscoding(false);
    setUploading(false);
  };

  useEffect(() => {
    if (!trackID) return;
    //fetch track and setTrack and play
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-api-key": process.env.REACT_APP_UDUX_API_KEY,
    });
    setPlaying(false);
    setLoading(true);
    fetch(`${process.env.REACT_APP_UDUX_API_URL}/tracks/byId/` + trackID, {
      headers,
    })
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.source) {
          setTrack(data.source);
          setPlaying(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setPlaying(false);
        // console.log(error)
      });
  }, [trackID]);

  return (
    <React.Fragment>
      <TrackPlayer
        loading={loading}
        playing={playing}
        source={track}
        onEnded={() => {
          setPlaying(false);
        }}
      />
      <Paper className={classes.root}>
        <Grid container>
          <LinearProgress
            color="secondary"
            variant={transcoding ? "indeterminate" : "determinate"}
            value={progress}
            style={{ width: "100%", marginBottom: 10 }}
          />
          <Grid container alignItems="center" justify="center">
            <PlayerButton
              record={record}
              iconProps={{ width: 30, height: 30 }}
              onClick={() => {
                setPlaying(!playing);
              }}
              loading={loading || uploading || transcoding}
              playing={playing}
              currentTrack={record}
            />
            <CustomUploadButton
              accept="audio/*"
              name={`${record.id}.temp`}
              storageRef={firebase.storage().ref(audioPath)}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadSuccess={handleUploadSuccess}
              onProgress={handleProgress}
              filename={record.id}
              style={{
                backgroundColor: Theme.palette.primary.dark,
                color: Theme.palette.common.black,
                padding: 10,
                borderRadius: 4,
              }}
            >
              {uploading ? "Uploading" : "Upload Audio"}
            </CustomUploadButton>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
});
