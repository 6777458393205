const addArtistName = async (requestHandler, type, resource, params) => {
  if (type === "UPDATE" || type === "CREATE") {
    try {
      if (params.data.artist) {
        const resp = await requestHandler("GET_ONE", "artists", {
          id: params.data.artist,
        });
        params.data.artist_name = resp.data.name || "";
      }
    } catch (error) {
      //console.log("addArtistName", error);
    }
  }
  return requestHandler(type, resource, params);
};
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
const getStatistics = async (resp) => {
  // get statistics
  const request = {
    IDs: resp.ids,
    config: {
      filter: [
        {
          name: "play_track",
        },
      ],
      buckets: ["streams", "device"],
    },
    queryType: "target",
  };
  try {
    const r = await fetch(
      `${process.env.REACT_APP_UDUX_BASE_API_URL}admin/v1/statistics`,
      {
        headers: {
          "content-type": "application/json",
          "x-api-key": process.env.REACT_APP_UDUX_API_KEY,
        },
        method: "POST",
        body: JSON.stringify(request),
      }
    ).then((r) => r.json());
    const devices = Object.keys(r.device);
    resp.ids.forEach((id, k) => {
      resp.data[k]["streams"] = formatNumber(r.streams[0][id]);
      devices.forEach((device) => {
        const d = device === "" ? "web" : device;
        resp.data[k][d] = formatNumber(r.device[device][id]);
      });
    });
    return r;
  } catch (error) {
    throw error;
  }
};

export const wrappedDataProvider = (requestHandler) => (
  type,
  resource,
  params
) => {
  console.log({type,
    resource,
    params})
  if (type === "GET_ONE") {
    if (resource === "playlists") {
      return requestHandler(type, resource, params)
        .then((resp) => {
          // //console.log(resp)
          try {
            if (resp.data.tracks) {
            }
          } catch (error) {}
          return resp;
        })
        .catch((error) => {
          // //console.log(error)
        });
    }
  } else if (type === "CREATE" || type === "UPDATE") {
    if (resource === "playlists") {
      if (
        type === "UPDATE" &&
        (!params.data.tracks || params.data.tracks.length === 0)
      ) {
        throw new Error("cannot save a playlist with no tracks");
      }
    }
    //console.log("CREATE ON THIS SIDE", type, resource, params);
    if (type === "CREATE" && resource === "tracks") {
      // //console.log("CREATE REQUEST FOR TASKS" ,type, resource, params);
      let releasedate = new Date(params.data.releasedate),
        releasedyear = releasedate.getFullYear(),
        releasedmonth = releasedate.getMonth() + 1,
        releasedday = releasedate.getDate();
      params.data["rd_year"] = releasedyear;
      params.data["rd_month"] = releasedmonth;
      params.data["rd_day"] = releasedday;

      let createddate = new Date(),
        createdyear = createddate.getFullYear(),
        createdmonth = createddate.getMonth() + 1,
        createdday = createddate.getDate();
      params.data["cd_year"] = createdyear;
      params.data["cd_month"] = createdmonth;
      params.data["cd_day"] = createdday;

      if (params.data.presave !== undefined) {
        delete params.data.presave;
      }

      if (params.data.trackName !== undefined) {
        params.data.name = params.data.trackName;
        delete params.data.trackName;
      }

      if (params.data.id !== undefined) {
        // this checks for the id of the album to fix our issue with records replacing themselves
        delete params.data.id;
        // return requestHandler(type, resource, params);
      }
    }

    if (resource === "banners") {
      if (params.data.link !== undefined) {
        let link = `${params.data.link}${params.data.entityId || ""}`;
        params.data.link = link;
        delete params.data.entity;
        delete params.data.entityId;
      }
    }

    if (type === "CREATE" && resource === "albums") {
      let releasedate = new Date(params.data.releasedate),
        releasedyear = releasedate.getFullYear(),
        releasedmonth = releasedate.getMonth() + 1,
        releasedday = releasedate.getDate();
      params.data["rd_year"] = releasedyear;
      params.data["rd_month"] = releasedmonth;
      params.data["rd_day"] = releasedday;

      let createddate = new Date(),
        createdyear = createddate.getFullYear(),
        createdmonth = createddate.getMonth() + 1,
        createdday = createddate.getDate();
      params.data["cd_year"] = createdyear;
      params.data["cd_month"] = createdmonth;
      params.data["cd_day"] = createdday;
      // return requestHandler(type, resource, params);
    }

    if (type === "UPDATE" && resource === "tracks") {
      if (params.data.durationChange > 0) {
        params.data.duration = params.data.durationChange;
        delete params.data.durationChange;
      }
      if (params.data.trackName !== undefined) {
        params.data.name = params.data.trackName;
        delete params.data.trackName;
      }

      if (params.data.artist_name_change !== undefined) {
        params.data.artist_name = params.data.artist_name_change;
        delete params.data.artist_name_change;
      }
      // //console.log("UPDATED TRACKS", params);
    }

    if (type === "UPDATE" && resource === "featured") {
      //console.log("Adding params", type, resource, params);
      if (params.previousData.q !== undefined) {
        if (params.data.type) {
          // params.data.type = "items";
        }
      }
    }

    /**CREATING SORTING METHOD FOR FILTERING TRACKS */
    if (resource === "tracks" || resource === "albums") {
      if (params.filter) {
        if (params.filter.releasedate) {
          // if (params.sort.field) params.filter.sort = "realeasedate"
          const startDate = new Date(params.filter.releasedate);
          const year = startDate.getFullYear();
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();
          params.filter["rd_year"] = year;
          params.filter["rd_month"] = month;
          params.filter["rd_day"] = day;
          delete params.filter.releasedate;
        }
      }
    }
    /**END OF CREATING SOFTING METHOD FOR FILTERING ARRAY */
    // return requestHandler(type, resource, params);

    if (resource === "push") {
      if (params.data && params.data.data) {
        Object.keys(params.data.data).forEach((key) =>
          params.data.data[key] === undefined
            ? delete params.data.data[key]
            : ""
        );
        if (params.data.data.scheduleAt && params.data.data.scheduleAt.format) {
          params.data.data.scheduleAt = params.data.data.scheduleAt.format();
        }
      } else if (params.data) {
        try {
          Object.keys(params.data).forEach((key) => {
            // //console.log(key)
            if (
              key === "undefined" ||
              key === undefined ||
              typeof key === "undefined"
            ) {
              delete params.data[key];
            }
          });
        } catch (error) {}

        let data;
        if (params.data.scheduleAt && params.data.scheduleAt.format) {
          params.data.scheduleAt = params.data.scheduleAt.format();
        }
        data = { ...params.data };
        if (params.previousData) {
          if (
            params.previousData.scheduleAt &&
            params.previousData.scheduleAt.format
          ) {
            params.previousData.scheduleAt = params.previousData.scheduleAt.format();
          }
          data = { ...params.previousData, ...data };
        }
        return fetch(
          `${process.env.REACT_APP_UDUX_BASE_API_URL}api/v1/push/byTopic${
            type === "UPDATE" ? "/" + data.id : ""
          }`,
          {
            headers: {
              "content-type": "application/json",
              // 'authorization': `Bearer ${window.localStorage.getItem("RAFirebaseClientToken")}`,
              "x-api-key": process.env.REACT_APP_UDUX_API_KEY,
            },
            method: type === "UPDATE" ? "PUT" : "POST",
            body: JSON.stringify(data),
          }
        )
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            return { data };
          });
      }
    }
    return addArtistName(requestHandler, type, resource, params);
  } else if (type === "GET_MANY") {
    const all = params.ids.map((id) =>
      requestHandler("GET_ONE", resource, { id })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          //removing all resovled errors from //console
          // //console.log("error returning id", err);
        })
    );
    return Promise.all(all).then((resp) => {
      return { data: resp.filter((v) => v !== undefined).map((r) => r.data) };
    });
  } else if (type === "GET_LIST") {
    if (resource === "push") {
      if (params.filter) {
        if (params.filter.scheduleAt)
          params.filter.scheduleAt = params.filter.scheduleAt.getTime();
        if (params.filter.created)
          params.filter.created = params.filter.created.getTime();
      }
    }

    if (params.filter && params.filter.q) {
      return fetch(
        `${process.env.REACT_APP_UDUX_BASE_API_URL}admin/v1/adminsearch`,
        {
          headers: {
            "content-type": "application/json",
            // 'authorization': `Bearer ${window.localStorage.getItem("RAFirebaseClientToken")}`,
            "x-api-key": process.env.REACT_APP_UDUX_API_KEY,
          },
          method: "POST",
          body: JSON.stringify([
            {
              indexName: params.filter.target || resource,
              params: {
                query: params.filter.q,
                hitsPerPage: 200,
              },
            },
          ]),
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then(async (resp) => {
          const data = resp.results[0];
          const r = { data: data[0].hits, total: data[0].hits.length };
          try {
            await getStatistics({
              ...r,
              ids: r.data.map((v) => v.id),
            });
          } catch (error) {}
          return r;
        })
        .catch((err) => {
          return { data: [], total: 0 };
        });
    }

    /**CREATING SORTING METHOD FOR FILTERING TRACKS */
    if (resource === "tracks" || resource === "albums") {
      // //console.log("GET_LIST from ra-data-firestore-json dataprovider", type, resource, params);
      if (params.filter) {
        if (params.filter.releasedate) {
          // if (params.sort.field) params.filter.sort = "realeasedate"
          const startDate = new Date(params.filter.releasedate);
          const year = startDate.getFullYear();
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();
          params.filter["rd_year"] = year;
          params.filter["rd_month"] = month;
          params.filter["rd_day"] = day;
          delete params.filter.releasedate;
        }
        if (params.filter.createdDate) {
          const startDate = new Date(params.filter.createdDate);
          const year = startDate.getFullYear();
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();
          params.filter["cd_year"] = year;
          params.filter["cd_month"] = month;
          params.filter["cd_day"] = day;
          delete params.filter.createdDate;
        }
      }
    }

    if (resource === "playlists") {
      //console.log("GET_LIST 1", type, resource, params);
      // return
      if (params.filter) {
        if (params.filter.createdDate) {
          // if (params.sort.field) params.filter.sort = "realeasedate"
          const startDate = new Date(params.filter.createdDate);
          const year = startDate.getFullYear();
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();
          params.filter["cd_year"] = year;
          params.filter["cd_month"] = month;
          params.filter["cd_day"] = day;
          delete params.filter.createdDate;
        }
      }
    }
    return requestHandler(type, resource, params).then(async (resp) => {
      try {
        await getStatistics(resp);
      } catch (error) {}
      return resp;
    });

    /**END OF CREATING SOFTING METHOD FOR FILTERING ARRAY */
    // return requestHandler(type, resource, params);
  } else if (type === "DELETE") {
    // CREATING SOFT DELETE FUNCTION
    ////console.log("DELETE REQUEST", type, resource, params);
    params.previousData.status = "deleted";
    params.data = params.previousData;
    type = "UPDATE";
    // return requestHandler(type, resource, params);
  }

  return requestHandler(type, resource, params);
};
