import React, { } from 'react';
import {
    TextField, Datagrid, List
} from 'react-admin'
import Pagination from '../../Pagination';
export default props => (
    <List {...props} title="Mail Templates" pagination={<Pagination />}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="contetn" />
        </Datagrid>
    </List>
)
