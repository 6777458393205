import React from 'react';
import { SimpleForm, Create, TextInput } from 'react-admin';
import { ScreenTitle } from './ScreenEdit';
import StatusInput from '../../Input/StatusInput';


export default props => (
    <Create {...props} title={<ScreenTitle create />} action={<div />}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="title" />
            <StatusInput />
        </SimpleForm>
    </Create>
)