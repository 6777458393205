import React, { Component } from "react";
import { addField, fetchEnd, fetchStart, showNotification } from "react-admin";

import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";
import EmailEditor from "react-email-editor";
import { Grid, Button } from "@material-ui/core";
import { compose } from "recompose";
import { CopyToClipboard } from "react-copy-to-clipboard";

class MailInput extends Component {
  state = {
    firstLoad: false,
    jdesign: null,
    html: null
  };
  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }
  exportHtml = () => {
    this.editor.current.exportHtml(data => {
      const { design } = data;
      const jdesign = JSON.stringify(design);
      this.props.input.onChange(jdesign);
      this.props.showNotification("Updating content");
    });
  };
  getHTML = () => {
    this.editor.current.exportHtml(data => {
      const { design, html } = data;
      const jdesign = JSON.stringify(design);
      this.setState({ jdesign, html });
    });
  };
  load = e => {
    if (this.props.record && this.props.record[this.props.source]) {
      const design = JSON.parse(this.props.record[this.props.source]);
      setTimeout(() => this.editor.current.loadDesign(design));
    }
  };
  render() {
    return (
      <Grid>
        <EmailEditor ref={this.editor} onLoad={this.load} />
        <Button
          style={{ margin: 20, alignSelf: "flex-end" }}
          variant="contained"
          onClick={this.exportHtml}
        >
          Update Content
        </Button>
        <CopyToClipboard
          text={this.state.html}
          onCopy={() =>
            alert("The mail template has been copied to your clipboard")
          }
        >
          <Button
            style={{ margin: 20, alignSelf: "flex-end" }}
            variant="contained"
            onClick={this.getHTML}
          >
            Get HTML
          </Button>
        </CopyToClipboard>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("select-content")(state)
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), addField);
export default enhance(MailInput);
