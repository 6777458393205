import React from "react";
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  Edit,
  TextInput,
  NumberInput,
} from "react-admin";
import StatusInput from "../../Input/StatusInput";
import UnixDateTimePicker from "../../Input/UnixDateTimePicker";
import { ShareAndWinInput } from "../../Input/ShareAndWin";
import PermissionsInput from "../../Input/PermissionsInput";
import { TrackUploader } from "./TrackUploader";
import { Grid } from "@material-ui/core";
import FileInput from "../../Input/FileInput";

const TrackTitle = ({ record }) => {
  return <span>Modify Track {record ? `"${record.name}"` : ""}</span>;
};

const EditFields = () => {
  return (
    <Grid container direction="column">
      <TextInput source="name" label="Track Name" />
      <ReferenceInput source="album" reference="albums" label="Album Name">
        <SelectInput
          optionText={(record) => record.name + " - " + record.artist_name}
        />
      </ReferenceInput>
      <TextInput source="album_name" label="Album Name" />
      <NumberInput source="album_index" label="Album Index" />
      <TextInput source="artist_name" label="Artist Name" />
      <TextInput source="upc" label="UPC" />
      <ReferenceInput source="genre" reference="genres" 
        perPage={600}
        sort={{ field: 'name', order: 'ASC' }}>
        <SelectInput optionText="name" />
      </ReferenceInput>

      <PermissionsInput />

      <UnixDateTimePicker label="Release Date" source="releasedate" />
      <StatusInput />
      <BooleanInput source="isPromo" label="Share and Win Eligible" />
      <ShareAndWinInput />

      <TextInput disabled style={{ display: "none" }} source="duration" />

      <TextInput
        disabled
        style={{ display: "none" }}
        source="durationChange"
        // defaultValue={this.state.time}
      />
    </Grid>
  );
};

export const EditSections = (props) => {
  return (
    <Grid container style={{ flex: 1 }}>
      <Grid item xs={12} md={5} style={{ marginRight: "45px" }}>
        <EditFields {...props} />
      </Grid>
      <Grid item xs={12} md={3}>
        <FileInput
          {...props}
          source="artwork"
          accept="image/*"
          button={{ title: "Upload Track Artwork" }}
        />
        <TrackUploader {...props} source="tracks" />
      </Grid>
    </Grid>
  );
};

const TracksEdit = (props) => {
  return (
    <Edit title={<TrackTitle />} {...props}>
      <SimpleForm {...props}>
        <EditSections />
      </SimpleForm>
    </Edit>
  );
};
export default TracksEdit;
