import React from 'react';
import { SimpleForm, TextInput, Edit, LongTextInput, Create, ImageInput, ImageField, ReferenceInput, SelectInput } from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import StatusInput from '../../Input/StatusInput';
import PermissionsInput from '../../Input/PermissionsInput';

const ArtistsTitle = ({ record }) => {
    return <span>Artists {record ? `"${record.title}"` : ''}</span>;
};


export const ArtistsEdit = props => (
    <Edit title={<ArtistsTitle />} {...props}>
        <SimpleForm>
            <ImageInput source="artwork" label="Artwork" accept="image/*">
                <ImageField source="artwork" title="title" />
            </ImageInput>
            <TextInput source="name" />
            <LongTextInput source="biography" />
            {/* <RichTextInput style={text} source="biography" toolbar={[['bold', 'italic', 'underline', 'link']]} /> */}
            
            <ReferenceInput source="cp" reference="cp" filterToQuery={(searchText) => ({ name: searchText, status: "active" })} perPage={100}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

            <PermissionsInput />

            <StatusInput />
        </SimpleForm>
    </Edit>
);

export const ArtistsCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/artists">
            <ImageInput source="artwork" label="Artwork" accept="image/*">
                <ImageField source="artwork" title="title" />
            </ImageInput>
            <TextInput source="name" />
            <LongTextInput source="biography" />
            {/* <RichTextInput source="biography" toolbar={[['bold', 'italic', 'underline', 'link']]} /> */}
            <ReferenceInput source="cp" reference="cp" filterToQuery={(searchText) => ({ name: searchText, status: "active" })} perPage={100}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

           <PermissionsInput />
            <StatusInput />
        </SimpleForm>
    </Create>
);