import React, { } from 'react';
import {
    ShowButton, DeleteButton, Filter, TextInput, FunctionField, EditButton, ImageField,
    EmailField, ReferenceField, TextField, Datagrid, List, Show, TabbedShowLayout, Tab, ReferenceManyField
} from 'react-admin'
import Pagination from '../../Pagination';
import moment from 'moment'


const UserTitle = ({ record }) => {
    return <span>User {record ? `"${record.email}"` : ''}</span>;
};

const UserFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="name" alwaysOn /> */}
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <TextInput label="Email" source="email" />
        <TextInput label="Provider" source="provider" allowEmpty />
    </Filter>
);

export const UserShow = props => (
    <Show {...props} title={<UserTitle />}>
        <TabbedShowLayout>
            <Tab label="summary">
                <FunctionField label="Joined" render={record => {
                    const date = moment(record.created)
                    return date.format("LLL")
                }} />
                <TextField source="email" />
                <TextField source="firstName" />
                <TextField source="lastName" />
            </Tab>
            <Tab label="Activity">
                <ReferenceManyField reference="statistics" target="owner" addLabel={false}>
                    <Datagrid>
                        <FunctionField label="Created" render={record => {
                            const date = moment(record.created)
                            return date.format("LLLL")
                        }} />
                        <TextField source="name" />
                        {/* <TextField source="device" /> */}
                        <TextField source="apiName" options={{ label: "Device" }} />
                        <ImageField source="artwork" />
                        <TextField source="country" />
                        {/* <TextField source="album_name" />
                        <TextField source="artist_name" /> */}
                        <TextField source="description" />
                        <ReferenceField source="genre" reference="genres" pagination={<Pagination />} linkType="show">
                            <TextField source="name" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Subscriptions">
                <ReferenceManyField reference="subscriptions" target="owner" addLabel={false}>
                    <Datagrid>
                        <FunctionField label="Expires" render={record => {
                            const date = moment(record.next_payment)
                            return date.format("LLL")
                        }} />
                        <ReferenceField source="plan" reference="plans" linkType="show">
                            <TextField source="title" />
                        </ReferenceField>
                        <TextField source="status" />
                        <FunctionField label="Modified" render={record => {
                            const date = moment(record.modified)
                            return date.format("LLL")
                        }} />
                        <FunctionField label="Created" render={record => {
                            const date = moment(record.created)
                            return date.format("LLL")
                        }} />
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default props => (
    <List {...props} filters={<UserFilter />} pagination={<Pagination />}>
        <Datagrid >
            <FunctionField label="Joined" render={record => {
                const date = moment(record.created)
                return date.format("LLL")
            }} />
            <TextField source="provider" />
            <TextField source="username" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="age" />
            <EmailField source="email" />
            <TextField source="gender" />
            <TextField source="location" />
            <TextField source="phone" />
            <ShowButton />
        </Datagrid>
    </List>
);