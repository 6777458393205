import React, { useState, useEffect } from 'react';
import { List, TextField, EditButton, CardActions, CreateButton, DeleteWithConfirmButton } from 'react-admin';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Section from './Section';
import { Grid } from '@material-ui/core';
import MList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = () => ({
    root: {
        width: '100%',
        maxWidth: 200,
        // backgroundColor: "#000",
        position: 'relative',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        // backgroundColor: 'inherit',
        padding: 0,
        backgroundColor: "#000",
    },
});


const Screens = withStyles(styles)(({ ids, data, basePath, classes, ...rest }) => {
    const [screen, setScreen] = useState(null);
    useEffect(() => {
        if (screen === null && ids.length > 0) {
            setScreen(data[ids[0]])
        }
    }, [screen, ids, data]);
    if (screen === null) return <div />
    return (
        <Grid container direction="row">
            <Grid item flex={0.4} className={classes.root}>
                <MList>
                    {ids.map((id) => {
                        const item = data[id]
                        return <ListItem dense button onClick={() => setScreen(item)}>
                            <ListItemText
                                primary={`${item.title}`}
                                secondary={item.sections ? `(${item.sections.length}) Sections` : ""}
                            />
                        </ListItem>
                    }
                    )}
                </MList>
            </Grid>
            <Grid item>
                <Card>
                    <CardHeader
                        title={<div><TextField record={screen} source="title" variant="heading" /> <span>Screen</span></div>}
                        action={
                            <React.Fragment>
                                <span>
                                    <EditButton {...rest} basePath={basePath} record={screen} />
                                </span>
                                <DeleteWithConfirmButton {...rest} basePath={basePath} record={screen} />
                            </React.Fragment>
                        } />
                    <CardContent>
                        <Grid container spacing={24} direction="row"
                            justify="center"
                            alignItems="space-around">
                            <Grid item>
                                <div className="marvel-device iphone-x">
                                    <div className="notch">
                                        <div className="camera"></div>
                                        <div className="speaker"></div>
                                    </div>
                                    <div className="top-bar"></div>
                                    <div className="sleep"></div>
                                    <div className="bottom-bar"></div>
                                    <div className="volume"></div>
                                    <div className="overflow">
                                        <div className="shadow shadow--tr"></div>
                                        <div className="shadow shadow--tl"></div>
                                        <div className="shadow shadow--br"></div>
                                        <div className="shadow shadow--bl"></div>
                                    </div>
                                    <div className="inner-shadow"></div>
                                    <div className="screen" style={{ backgroundColor: "#000" }}>
                                        {(screen && screen.sections) ?
                                            screen.sections.map(sectionID => <div key={sectionID} style={{ paddingTop: 60, paddingHorizontal: 30 }}>
                                                <Section sectionID={sectionID} />
                                            </div>) : <div />
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="marvel-device note8">
                                    <div className="notch">
                                        <div className="camera"></div>
                                        <div className="speaker"></div>
                                    </div>
                                    <div className="top-bar"></div>
                                    <div className="sleep"></div>
                                    <div className="bottom-bar"></div>
                                    <div className="volume"></div>
                                    <div className="overflow">
                                        <div className="shadow shadow--tr"></div>
                                        <div className="shadow shadow--tl"></div>
                                        <div className="shadow shadow--br"></div>
                                        <div className="shadow shadow--bl"></div>
                                    </div>
                                    <div className="inner-shadow"></div>
                                    <div className="screen" style={{ backgroundColor: "#000" }}>
                                        {(screen && screen.sections) ?
                                            screen.sections.map(sectionID => <div key={sectionID} style={{ paddingTop: 60, paddingHorizontal: 30 }}>
                                                <Section sectionID={sectionID} />
                                            </div>) : <div />
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
})

Screens.defaultProps = {
    ids: [],
    data: {},
};

export const SectionListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
        <CardActions>
            <CreateButton basepath={basePath} />
        </CardActions>
    )
export default (props) => (
    <List
        title="All Screens"
        {...props} pagination={<div />}
        actions={<SectionListActions />}
    >
        <Screens />
    </List>
)
