import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  BooleanField,
  FunctionField,
  ChipField,
} from "react-admin";
import Pagination from "../../Pagination";
import { PermissionsField } from "../../Input/PermissionsInput";

export default (props) => {
  return (
    <List {...props} title="Moods" pagination={<Pagination />}>
      <Datagrid rowClick="edit">
        <ImageField className="size" source="artwork" title="artwork" />
        <TextField source="name" />
        <BooleanField source="public" />
        <FunctionField
          label="Track Count"
          render={(record) => {
            if (record.tracks) return Object.keys(record.tracks).length;
            return 0;
          }}
        />
        <ChipField source="status" />

        <PermissionsField />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};
