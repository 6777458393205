import React from 'react';
import {
    List, Datagrid, TextField, EmailField, ImageField, EditButton, ShowButton,
    SelectField
} from 'react-admin';
import { TextInput, Filter } from 'react-admin';
import { TargetReferenceArrayField } from './Fields/TargetReferenceArrayField';
import Pagination from './Pagination';
import DeleteWithConfirmButton from 'ra-ui-materialui/lib/button/DeleteWithConfirmButton';

//DISPLAYS THE CATEGORIES FROM THE MENU IN A SPREADSHEET FORMAT


const ArtistsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="Name" source="name" allowEmpty />
    </Filter>
);

const Artists = props => (
    <List filters={<ArtistsFilter />} {...props}>
        <Datagrid rowClick="edit">
            <ImageField className="size" source="artwork" title="artwork" />
            <TextField source="name" />
            <TextField source="biography" />
            <EditButton label="Edit" />
            <ShowButton label="Show" />
        </Datagrid>
    </List>
);


const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="UserID" source="userID" allowEmpty />
        <TextInput label="Firstname" source="firstname" allowEmpty />
        <TextInput label="Lastname" source="lastname" allowEmpty />
        <TextInput label="Email" source="email" allowEmpty />
        <TextInput label="Location" source="location" allowEmpty />
        <TextInput label="Age" source="age" allowEmpty />
        <TextInput label="Gender" source="Gender" allowEmpty />
        <TextInput label="Bank Provider" source="Provider" allowEmpty />
    </Filter>
);

const User = props => (
    <List filters={<UserFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="userID" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="location" />
            <TextField source="age" />
            <TextField source="gender" />
            <TextField source="provider" />
            <EditButton label="Edit" />
            <ShowButton label="Show" />
        </Datagrid>
    </List>
);


// const TargetReferenceArrayField = (props) => {
//     return <ReferenceArrayField {...props} label={props.record.target} reference={props.record.target} source="items">
//         <SingleFieldList>
//             <ImageField className="mini-artwork" source="artwork" title="artwork" />
//         </SingleFieldList>
//     </ReferenceArrayField>
// }

const Featured = props => (
    <List {...props} title="Featured Content" pagination={<Pagination />}>
        <Datagrid rowClick="edit">
            <TextField source="type" />
            <TextField source="title" />
            <TextField source="name" />
            <TargetReferenceArrayField targetField="items" />
            <EditButton label="Edit" />
            {/*<ShowButton label="Show"/>, */}
            <SelectField source="status" choices={[
                { id: 'active', name: 'Active' },
                { id: 'inactive', name: 'Inactive' },
            ]} />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
)
export {
    Artists,
    User,
    Featured,
}