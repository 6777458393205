import React from "react";
import {
  ReferenceArrayInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TextInput,
  Edit,
  NumberInput,
  AutocompleteInput,
  ReferenceInput
} from "react-admin";
import { TabbedForm, FormTab } from "react-admin";
import FeaturedItemsList from "../../Input/FeaturedItemsList";
import StatusInput from "../../Input/StatusInput";
import { entityChoices } from "./Constants";

const FeaturedSectionTitle = ({ record }) => {
  return <span>Edit Section - {record ? `"${record.name}"` : ""}</span>;
};

const featureChoices = {
  albums: [
    { id: "artist", name: "Artist" },
    { id: "genre_name", name: "Genre  name" },
    { id: "name", name: "Album Name" },
    { id: "label", name: "Album Label" },
    { id: "albumtype", name: "Album Type" }
  ],
  artists: [{ id: "name", name: "Artists Name" }],
  tracks: [
    { id: "artist_name", name: "Artist Name" },
    { id: "album_name", name: "Album  name" },
    { id: "name", name: "Track Name" },
    { id: "label", name: "Track Label" },
    { id: "genre_name", name: "Genre name" }
  ],
  playlists: [{ id: "name", name: "Playlist Name" }],
  utilities: [
    { id: "name", name: "Utility Name" },
    { id: "title", name: "Utility Title" }
  ],
  comments: [
    { id: "comment", name: "Comment" },
    { id: "target", name: "Content Section" }
  ],
  banners: [
    { id: "subtitle", name: "Banner Subtitle" },
    { id: "title", name: "Banner Title" }
  ],
  moods: [
    { id: "name", name: "Mood name" },
    { id: "playlist_type", name: "Playlist Type" }
  ],
  videos: [
    { id: "albumName", name: "ALbum Name" },
    { id: "description", name: "Description" }
  ]
};

const InputField = props => {
  let { showInput } = props,
    theField =
      showInput === "artist" ? (
        <ReferenceInput source="val" label="Value" reference="artists">
          <AutocompleteInput optionText={choices => `${choices.name || ""}`} />
        </ReferenceInput>
      ) : (
        <TextInput source="val" label="Value" />
      );

  return theField;
};

export const TargetReferenceArrayInput = props => {
  // console.log("PROPS FROM OPTIONS TAB TARGETREFERENCE ARRAY INPUT", props);
  return (
    <ReferenceArrayInput
      {...props}
      source="items"
      reference={props.record.target}
      label={props.record.target}
    >
      <FeaturedItemsList optionText="name" />
    </ReferenceArrayInput>
  );
};

export const OptionsTab = props => {
  // console.log("data passed to options", props);
  let [selected, setSelected] = React.useState(""),
    Field = e => {
      let val = Object.keys(e).map(val => e[val]);
      val.pop(val.length - 1);
      let value = val.join("");
      setSelected(value);
    };

  if (!props || !props.record) return null;
  if (props.record.type === "items")
    return <TargetReferenceArrayInput {...props} />;
  if (props.record.type === "query")
    return (
      <React.Fragment>
        <ArrayInput {...props} source="q">
          <SimpleFormIterator>
            {/* <TextInput source="name" label="Field Name" /> */}
            <SelectInput
              label="Field Name"
              source="name"
              choices={featureChoices[props.record.target]}
              onChange={Field}
            />
            <SelectInput
              source="op"
              choices={[{ id: "==", name: "Equals" }]}
              label="Comparator"
            />
            <InputField showInput={selected} {...props} />
          </SimpleFormIterator>
        </ArrayInput>
      </React.Fragment>
    );
  return null;
};

export default props => {
  console.log("PROPS FROM SECTIONS edit", props);
  return (
    <Edit {...props} title={<FeaturedSectionTitle />}>
      <TabbedForm>
        <FormTab label="Options">
          <OptionsTab />
        </FormTab>
        <FormTab label="Details">
          <TextInput source="name" />
          <TextInput source="title" />
          <SelectInput
            source="type"
            choices={[
              { id: "items", name: "List of Content" },
              { id: "query", name: "Search for Content" }
            ]}
          />
          <SelectInput source="target" choices={entityChoices} />
          <StatusInput label="Status" />
          <NumberInput source="displayLimit" label="Display size" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
