import React from 'react';
import { Query, Loading, ImageField } from 'react-admin';
import { Grid } from '@material-ui/core';

export default ({
    ids,
    resource,}) => {
    return <Query type="GET_MANY" resource={resource} payload={{ ids }}>
        {({ data, loading, error }) => {
            if (loading) return <Loading />
            if (error) return <p></p>
            return <Grid container alignItems="flex-start"
                        justify="center"
                        direction="row" 
                        style={{
                            width: 1000,
                            height: 120
                        }}>{data.map(item => <Grid item key={item.id} >
                            <ImageField source={item.image ? "image" : "artwork"} title={item.name ? "name" : "title"} record={item} style={{
                                height: 95,
                                width: 95,
                                // marginLeft: 10,
                                borderRadius: 5,
                            }} />
                        </Grid>)}
                    </Grid>
                }}
            </Query>
        } 