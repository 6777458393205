/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
const initialState = {
  progress: 0,
  data: "Initial Data"
};


function UploadReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPLOAD_TEST':
      let progresser = state.progress;
          progresser += action.data;
      let stater = {
          ...state,
          progress : progresser
      };
      // console.log("FROM THE REDUCER 2", state, action);
      return stater;

    case "UPLOAD":
      let newProgress = state.progress;
      newProgress += action.data;
      let newState = {
        ...state,
        progress: newProgress
      };

      console.log("REDUCER ARGs", state, action);
      return newState;

    case "UPLOAD_COMPLETE":
    //   console.log("REDUCER ARGs", state, action);

    case "UPLOAD_ERROR":
      // do nothing for now
  }
  return state;
}

export default UploadReducer;
