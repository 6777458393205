import React, { } from 'react';
import {
    Filter, ReferenceInput, SelectInput, TextInput, FunctionField, ImageField, ReferenceField, TextField, Datagrid, List
} from 'react-admin'
import Pagination from '../../Pagination';
import moment from 'moment'


const StatisticsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Owner" source="owner" />
        <SelectInput {...props} source="device" choices={[
            { _id: "ios", name: 'iOS' },
            { _id: "android", name: 'Android' },
        ]} optionText="name" optionValue="_id" />
        <SelectInput {...props} source="name" choices={[
            { _id: "play_track", name: 'Play Track' },
            { _id: "valid_play_track", name: 'Effective Stream' },
            { _id: "screen_view", name: 'Screen View' },
        ]} optionText="name" optionValue="_id" />
        {/* <TextInput label="Genre" source="genre" /> */}
    </Filter>
);

export const StatisticList = props => (
    <List filters={<StatisticsFilter />} {...props} pagination={<Pagination />}>
        <Datagrid  >
            <FunctionField label="Created" render={record => {
                const date = moment(record.created)
                return date.format("LLL")
            }} />
            <ReferenceField source="owner" reference="user">
                <TextField source="id" />
            </ReferenceField>
            <TextField label="Activity" source="name"/>
            <TextField source="description" />
            {/* <TextField source="apiName" /> */}
            <ImageField className="statistics_image_reduction" source="artwork" />
            <ReferenceField source="genre" reference="genres">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="album_name" />
            <TextField source="artist_name" />
            {/* <NumberField source="modified" />
            <TextField source="source" />
            <TextField source="sourceID" />
            <TextField source="target" />
            <TextField source="targetID" />
            <TextField source="id" />
            <TextField source="album" />
            <TextField source="artist" /> */}
            <TextField source="country" />
            <TextField source="device" />
        </Datagrid>
    </List>
);