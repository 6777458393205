import { Button } from "@material-ui/core";
import React, { useRef } from "react";

const CopyToClipboardField = ({ field, record }) => {
  const textEl = useRef(null);
  const copyCodeToClipboard = () => { 
    textEl.current.select();
    document.execCommand("copy");
    window.alert("Copied");
  };

  return (
    <div>
      <div>
        <textarea
          style={{ width: 0,height:0, position:'absolute', }}
          value={record[field]}
          ref={textEl}
        />
      </div>
      <div>
        <Button onClick={() => copyCodeToClipboard()}>
          Copy {field} to Clipboard
        </Button>
      </div>
    </div>
  );
};

CopyToClipboardField.defaultProps = {};

export default CopyToClipboardField;
