import React, { useEffect } from "react";
import LinkOptionText from "../../Libs/LinkOptionText";
import { Grid } from "@material-ui/core";
import {
  ReferenceInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  FormDataConsumer,
  AutocompleteInput,
  REDUX_FORM_NAME,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { change } from "redux-form";
import StatusInput from "../../Input/StatusInput";
import { ShareAndWinInput } from "../../Input/ShareAndWin";
import { DateTimeInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import PermissionsInput from "../../Input/PermissionsInput";
import FileInput from "../../Input/FileInput";
import { EditSections as TrackEditSections } from "../Track/TrackEdit";

const AlbumsEditTitle = ({ record }) => {
  return <span> Edit Album {record ? `"${record.name || ""}"` : ""}</span>;
};

const AlbumsCreateTitle = ({ record }) => {
  return <span> Create Album</span>;
};

const dateFormatter = (v) => {
  if (typeof v === undefined) return new Date();
  if (!(v instanceof Date) || isNaN(v)) return new Date(v);
  return v;
};

const dateParser = (v) => {
  const d = new Date(v);
  if (isNaN(d)) return;
  return d.getTime();
};

const GenreName = ({ genre, choices, dispatch }) => {
  const selectedGenre = choices.filter((v) => v.id === genre)[0];
  useEffect(() => {
    if (selectedGenre)
      dispatch(change(REDUX_FORM_NAME, "genre_name", selectedGenre.name));
  }, [dispatch, genre, selectedGenre]);
  return null;
};

export const EditFields = () => {
  return (
    <Grid container direction="column">
      <TextInput source="name" label="Album Name" />
      <ReferenceInput label="Artist" source="artist" reference="artists">
        <AutocompleteInput
          optionText={(choice) => LinkOptionText(choice, "artists")}
        />
      </ReferenceInput>
      <TextInput source="upc" />
      <ReferenceInput
        source="cp"
        reference="cp"
        filterToQuery={(searchText) => ({
          name: searchText,
          status: "active",
        })}
        perPage={100}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput source="label" label="Label" />

      <PermissionsInput />

      <ReferenceInput
        source="genre"
        label="Genres"
        reference="genres"
        perPage={600}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData && formData.genre) {
            return (
              <ReferenceInput
                label={`Genre Name`}
                source="genre_name"
                reference={"genres"}
                perPage={600}
                sort={{ field: "name", order: "ASC" }}
              >
                <GenreName genre={formData.genre} {...rest} />
              </ReferenceInput>
            );
          }
          return <></>;
        }}
      </FormDataConsumer>

      <SelectInput
        label="Album Type"
        source="albumtype"
        choices={[
          { id: "single", name: "Single" },
          { id: "Ep", name: "Ep" },
          { id: "Albums", name: "Albums" },
        ]}
      />
      <StatusInput />
      <NumberInput source="year" label="Release Year" />
      <DateTimeInput
        label="Release Date"
        source="releasedate"
        parse={dateParser}
        format={dateFormatter}
        options={{ format: "DD/MM/YYYY" }}
        providerOptions={{ utils: MomentUtils }}
      />
      <BooleanInput source="isPromo" label="Share and Win Eligible" />
      <BooleanInput label="Pre Save" source="presave" />
      <ShareAndWinInput />
    </Grid>
  );
};
export const EditSections = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={5} style={{ marginRight: "45px" }}>
        <EditFields {...props} />
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid>
          <FileInput
            {...props}
            source="artwork"
            accept="image/*"
            button={{ title: "Upload Album Artwork" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AlbumsEdit = (props) => {
  return (
    <Edit title={<AlbumsEditTitle />} {...props}>
      <SimpleForm redirect={`${props.basePath}/${props.id}`}>
        <EditSections />
        <ArrayInput source="Tracks">
          <SimpleFormIterator style={{ width: "100vw" }}>
            <TrackEditSections />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const AlbumsCreate = (props) => {
  return (
    <Create title={<AlbumsCreateTitle />} {...props}>
      <SimpleForm redirect="/albums">
        <EditSections />
      </SimpleForm>
    </Create>
  );
};
