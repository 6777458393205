import React, { Fragment } from 'react'
import ReorderInput from '../Input/ReorderInput';
import AddFeaturedItem from '../Input/AddFeaturedItem';
import { FormDataConsumer, ImageField } from 'react-admin';
import { Button } from '@material-ui/core';

import IconContentClose from '@material-ui/icons/Close';
import { withDataProvider } from 'ra-core';

// To do move this to new field component called ItemSelectorInput
export default withDataProvider((props) => {
    const onAddItem = (item) => {
        console.log(item)
        // console.log("DATA FLOW IN REST ", props)
    }
    console.log("PROPS FROM FEATUREDITEMSLIST ", props);

    return (
        <FormDataConsumer>
            {({ ...rest }) => {
                return (
                    <Fragment>
                        <AddFeaturedItem target={props.label} onAddItem={onAddItem} {...rest} />
                        <ReorderInput {...props}
                            choices={props.choices.filter((c) => props.input.value.includes(c.id))}
                            renderItem={(item) =>
                                <div style={{
                                    display: "inline-block",
                                    position: "relative"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justify: "center",
                                        alignItems: "center"
                                    }}>
                                        {rest.formData.target === "videos" ?
                                            <ImageField source="pictures.sizes.2.link" title="title" record={item} />
                                            : <ImageField source="artwork" title="title" record={item} />}

                                        <span style={{ fontSize: "0.6em" }}>{item.artist_name} - {item.name}</span>
                                    </div>
                                    <Button
                                        className=""
                                        onClick={() => {
                                            // console.log(item)
                                            const newItems = rest.formData.items.filter(function (value) {
                                                return item.id !== value;
                                            })
                                            props.input.onChange(newItems)
                                        }}
                                        label="" style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                        }}>
                                        <IconContentClose />
                                    </Button>
                                </div>
                            } />
                    </Fragment>
                )
            }}
        </FormDataConsumer>
    )
})