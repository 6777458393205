import React from 'react';
import { SimpleForm, TextInput, Create } from 'react-admin';
import MailInput from './MailInput';


const MailTitle = ({ record }) => {
    return <span>Mail Template - {record ? `"${record.name}"` : ''}</span>;
};

export default props => (
    <Create title={<MailTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <MailInput source="content" />
        </SimpleForm>
    </Create>
);

