import * as React from "react";
import "./App.css";
import "Devices.css/assets/devices.min.css";
import { Admin, ListGuesser, EditGuesser, Login } from "react-admin";
import { Featured } from "./DATA";
import { TracksCreate, GenresCreate, GenresEdit } from "./Edit";
import englishMessages from "ra-language-english";
import tinymce from "tinymce/tinymce";
// ICONS,
import TracksIcon from "@material-ui/icons/PlayCircleFilled";
import PlaylistsIcon from "@material-ui/icons/PlaylistPlay";
import GenresIcon from "@material-ui/icons/MusicNote";
import ArtistsIcon from "@material-ui/icons/Brush";
import UserIcon from "@material-ui/icons/People";
import AlbumsIcon from "@material-ui/icons/Album";
import VideosIcon from "@material-ui/icons/VideoLibrary";
import MailIcon from "@material-ui/icons/Mail";
import { Resource } from "ra-core";
import MoodList from "./Resource/Mood/MoodList";
import BannerList from "./Resource/Banner/BannerList";
import AlbumList, { AlbumsShow } from "./Resource/Album/AlbumList";
import { AlbumsCreate, AlbumsEdit } from "./Resource/Album/AlbumModify";
import ArtistShow from "./Resource/Artist/ArtistShow";
import PlaylistList from "./Resource/Playlist/PlaylistList";
import { ArtistsCreate, ArtistsEdit } from "./Resource/Artist/ArtistModify";
import UserList, { UserShow } from "./Resource/User/UserList";
import ArtistList from "./Resource/Artist/ArtistList";
import {
  PlaylistsCreate,
  PlaylistsEdit,
} from "./Resource/Playlist/PlaylistModify";
import PlaylistShow from "./Resource/Playlist/PlaylistShow";
import { RestProvider, AuthProvider } from "ra-data-firestore-client";
import TrackList from "./Resource/Track/TrackList";
import TracksEdit from "./Resource/Track/TrackEdit";
import { PlanList, PlanShow } from "./Resource/Plan";
import Theme from "./Theme";
import { wrappedDataProvider } from "./dataProvider";
import { PushList, PushCreate, PushEdit } from "./Resource/Push";
import MyLayout from "./Layout";
import MailList from "./Resource/Mail/MailList";
import MailCreate from "./Resource/Mail/MailCreate";
import MailEdit from "./Resource/Mail/MailEdit";
import { ShowGuesser } from "ra-ui-materialui/lib/detail";
import { StatisticList } from "./Resource/Statistics";
import ScreenList from "./Resource/Screens/ScreenList";
import ScreenEdit from "./Resource/Screens/ScreenEdit";
import VideoList from "./Resource/Video/VideoList";
import { GenresList } from "./Resource/Genres";
import SectionEdit from "./Resource/Screens/SectionEdit";
import SectionCreate from "./Resource/Screens/SectionCreate";
import ScreenCreate from "./Resource/Screens/ScreenCreate";
import { UtilityList, UtilityEdit, UtilityCreate } from "./Resource/Utility";
import { PromolinkList } from "./Resource/PromoLinks";
import { PromoList, PromoCreate, PromoEdit } from "./Resource/Promo";
import { CpList, CpCreate, CpEdit } from "./Resource/CP";
import UploadReducer from "./reducer";
import BannerCreate from "./Resource/Banner/BannerCreate";
import "./Services/firebase";
import SetupFirebase, { config } from "./Services/firebase";
import BannerEdit from "./Resource/Banner/BannerEdit";
import { PlayerProvider } from "./Context/PlayerProvider";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import ReactPlayer from "react-player";

SetupFirebase();

const trackedResources = [
  { name: "banners", isPublic: true },
  { name: "user", isPublic: true },
  { name: "push" },
  { name: "mailtemplates" },
];

const authConfig = {
  userProfilePath: "/user/",
  userAdminProp: "admin",
};

const messages = {
  en: englishMessages,
};
const i18nProvider = () => messages["en"];

const dataProvider = wrappedDataProvider(
  RestProvider(config, {
    trackedResources,
    timestampFieldNames: {
      createdAt: "created",
      updatedAt: "modified",
    },
  })
);

const Background = () => (
  <Login backgroundImage="https://static-staging.udux.com/e9ddaee0388c5c5fbe4f23224856f4cfc5fee4b3d64fcb3e5e46eed7bacbdd9e.png?q=8b86404c335ac2fa5b55145078c5e99ccea8d1c8839536c3daeb8bce8646715d" />
);

const AppPlayer = ({ setaudio, setLoaded, ...props }) => {
  const [track, setTrack] = React.useState(null);
  const playerRef = React.useRef(null);
  return (
    <React.Fragment>
      <ReactPlayer
        width={0}
        height={0}
        playsinline
        ref={playerRef}
        url={track}
        playing={true}
        // onPlay={() => onPlay && onPlay()}
        // onBuffer={() => onBuffer && onBuffer()}
        // onError={(...props) => onError && onError(...props)}
        // onEnded={() => onEnded && onEnded()}
      />
      <ReactJkMusicPlayer
        getAudioInstance={(instance) => {
          setaudio(instance);
          setLoaded(true);
        }}
        onAudioPlay={(info) => {
          setTrack(info.musicSrc);
        }}
      />
    </React.Fragment>
  );
};
const authProvider = AuthProvider(authConfig);
const App = () => {
  // react-tinymce use global ref
  const [audio, setaudio] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [track, setTrack] = React.useState(null);
  const playerRef = React.useRef(null);
  window.tinymce = tinymce;

  // const [mode] = React.useState(process.env.REACT_APP_UDUX_MODE)
  if (window.location.pathname.indexOf("marketing") !== -1) {
    return (
      <Admin
        theme={Theme}
        appLayout={MyLayout}
        title="uduX - Marketing Admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
        locale="en"
        i18nProvider={i18nProvider}
        // RealTimeSage={FirebaseRealTimeSaga}
      >
        <Resource
          name="push"
          options={{ label: "Push Notifications" }}
          list={PushList}
          create={PushCreate}
          edit={PushEdit}
        />
        <Resource
          name="mailtemplates"
          options={{ label: "Mail Templates" }}
          list={MailList}
          edit={MailEdit}
          create={MailCreate}
          icon={MailIcon}
        />
        <Resource
          name="albums"
          list={AlbumList}
          icon={AlbumsIcon}
          show={AlbumsShow}
          edit={AlbumsEdit}
        />
        <Resource name="videos" list={ListGuesser} icon={AlbumsIcon} />
        <Resource
          name="artists"
          list={ArtistList}
          icon={ArtistsIcon}
          show={ArtistShow}
        />
        <Resource name="tracks" list={TrackList} icon={TracksIcon} />
        <Resource
          name="playlists"
          list={PlaylistList}
          show={PlaylistShow}
          icon={PlaylistsIcon}
        />
        <Resource name="promolinks" list={PromolinkList} />
        <Resource
          name="promo"
          list={PromoList}
          create={PromoCreate}
          edit={PromoEdit}
        />
        <Resource name="cp" />
        <Resource name="genres" />
        <Resource name="user" />
        <Resource name="subscriptions" />
        <Resource name="plans" />
        <Resource name="moods" />
      </Admin>
    );
  }
  if (window.location.pathname.indexOf("useradmin") !== -1) {
    return (
      <PlayerProvider audio={audio}>
        <Admin
          theme={Theme}
          appLayout={MyLayout}
          title="uduX - User Admin"
          locale="en"
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={Background}
          // RealTimeSage={FirebaseRealTimeSaga}
        >
          <Resource
            name="user"
            options={{ label: "Users" }}
            list={UserList}
            icon={UserIcon}
            edit={EditGuesser}
            show={UserShow}
          />
          <Resource
            name="subscriptions"
            list={ListGuesser}
            create={EditGuesser}
            edit={EditGuesser}
            show={ShowGuesser}
          />
          <Resource
            name="statistics"
            list={StatisticList}
            create={EditGuesser}
            edit={EditGuesser}
            show={ShowGuesser}
          />
          <Resource
            name="connections"
            list={ListGuesser}
            create={EditGuesser}
            edit={EditGuesser}
            show={ShowGuesser}
          />
          <Resource name="plans" list={PlanList} show={PlanShow} />
          <Resource name="role" list={ListGuesser} />
        </Admin>
      </PlayerProvider>
    );
  }
  if (window.location.pathname.indexOf("crashing") !== -1) {
    return (
      <PlayerProvider audio={audio}>
        <Admin
          theme={Theme}
          appLayout={MyLayout}
          title="uduX - Crash Admin"
          locale="en"
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={Background}
          // RealTimeSage={FirebaseRealTimeSaga}
        >
          <Resource
            name="errorreport"
            list={ListGuesser}
            create={EditGuesser}
            edit={EditGuesser}
            show={ShowGuesser}
          />
          <Resource name="user" />
        </Admin>
      </PlayerProvider>
    );
  }
  return (
    <React.Fragment>
      <AppPlayer setaudio={setaudio} setLoaded={setLoaded} />
      <PlayerProvider audio={audio}>
        {loaded && (
          <Admin
            theme={Theme}
            appLayout={MyLayout}
            title="uduX - Content Admin"
            locale="en"
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={Background}
            customReducers={{ UploadReducer }}
          >
            <Resource
              name="screens"
              options={{ label: "Screens" }}
              list={ScreenList}
              edit={ScreenEdit}
              create={ScreenCreate}
            />
            <Resource
              name="featured"
              options={{ label: "Sections" }}
              list={Featured}
              icon={UserIcon}
              edit={SectionEdit}
              create={SectionCreate}
            />
            <Resource
              name="statistics"
              list={StatisticList}
              options={{ label: "User Activity" }}
            />
            <Resource
              name="albums"
              list={AlbumList}
              create={AlbumsCreate}
              edit={AlbumsEdit}
              icon={AlbumsIcon}
              show={AlbumsShow}
            />
            <Resource name="videos" list={VideoList} icon={VideosIcon} />
            <Resource
              name="artists"
              list={ArtistList}
              create={ArtistsCreate}
              edit={ArtistsEdit}
              icon={ArtistsIcon}
              show={ArtistShow}
            />
            <Resource
              name="tracks"
              list={TrackList}
              create={TracksCreate}
              edit={TracksEdit}
              icon={TracksIcon}
            />
            <Resource
              name="playlists"
              list={PlaylistList}
              create={PlaylistsCreate}
              edit={PlaylistsEdit}
              show={PlaylistShow}
              icon={PlaylistsIcon}
            />
            <Resource
              name="genres"
              list={GenresList}
              create={GenresCreate}
              edit={GenresEdit}
              icon={GenresIcon}
            />
            <Resource name="subscriptions" />
            <Resource
              name="utilities"
              list={UtilityList}
              edit={UtilityEdit}
              create={UtilityCreate}
            />
            <Resource name="moods" list={MoodList} edit={PlaylistsEdit} />
            <Resource
              name="banners"
              list={BannerList}
              edit={BannerEdit}
              create={BannerCreate}
            />
            <Resource name="feeds" />
            <Resource name="events" />
            <Resource name="comments" />
            <Resource name="user" />
            <Resource name="cp" list={CpList} edit={CpEdit} create={CpCreate} />
          </Admin>
        )}
      </PlayerProvider>
    </React.Fragment>
  );
};

export default App;
