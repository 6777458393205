import React from 'react'

import { List, Datagrid, DateField, TextField, ReferenceField, SimpleForm, Edit, Create, AutocompleteInput, SelectInput, ReferenceInput, FormDataConsumer } from 'react-admin'
import Pagination from '../../Pagination'
import { entityChoices } from '../Screens/Constants';
import { Grid } from '@material-ui/core';
import UnixDateTimePicker from '../../Input/UnixDateTimePicker';
import PushArtwork from '../../Fields/PushArtwork';
import { EditButton } from 'ra-ui-materialui/lib/button';

export const PromoList = props => (
    <List {...props} pagination={<Pagination />}>
        <Datagrid>
            <PushArtwork />
            <TextField source="target" label="Target" />
            <TextField source="type" label="Promo Type" />
            <DateField showTime source="startDate" label="Start Date" />
            <DateField showTime source="endDate" label="End Date" />
            <EditButton />
        </Datagrid>
    </List>
);
export const PromoTargetField = (props) => {
    return <ReferenceField source="targetID" reference={props.target}>
        <TextField source="name" />
    </ReferenceField>
}
export const PromoEdit = props => (
    <Edit {...props} >
        <Form />
    </Edit>
)
const Form = (props) => <SimpleForm {...props}>
    <SelectInput source="target" choices={entityChoices} />
    <FormDataConsumer {...props}>
        {({ formData }) => {
            if (formData.target) {
                return <Grid container direction="row" style={{ width: "1.2em" }}>
                    <Grid>
                        <SelectInput source="type" choices={[
                            { id: "share", name: "Share and Win" },
                        ]} />
                    </Grid>
                    <Grid>
                        <ReferenceInput {...props}
                            filterToQuery={(searchText) => ({ q: searchText })}
                            label={`Select item to add`} source={"targetID"} reference={formData.target}>
                            <AutocompleteInput optionText={(choice) => {
                                if (choice.album_name) {
                                    return `${choice.artist_name} - ${choice.album_name} - ${choice.name}`
                                } else if (choice.artist_name) {
                                    return `${choice.artist_name} - ${choice.name}`
                                }
                                return `${choice.artist_name || ""} - ${choice.album_name || ""} - ${choice.name || choice.title || choice.id || "unknown"}`
                            }} />
                        </ReferenceInput>
                    </Grid>
                    <Grid>
                        <UnixDateTimePicker {...props} source="startDate" label="Promo Start Time" options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />
                    </Grid>
                    <Grid>
                        <UnixDateTimePicker {...props} source="endDate" label="Promo End Time" options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />
                    </Grid>
                </Grid>
            }
            return null
        }}
    </FormDataConsumer>
</SimpleForm>
export const PromoCreate = props => (
    <Create {...props} >
        <Form />
    </Create>
)