import React from 'react';
import {
    TextField, ImageField, ChipField, Show, TabbedShowLayout, Tab, ReferenceManyField
} from 'react-admin';


const PlaylistArtistList = props => {
    // const artists = props.record.artists ? Object.keys(props.record.artists) : []
    // console.log(record)
    // return <ReferenceManyField {...{ ...props, record }} source={props.source} reference={"artists"} >
    //     <TextField source="name" />
    // </ReferenceManyField>
    return <ReferenceManyField {...props}  >
        <TextField source="name" />
    </ReferenceManyField>
}

export default (props) => {
    return (
        <Show  {...props}>
            <TabbedShowLayout>
                <Tab label="DETAILS">
                    <TextField source="sharelink" />
                    <ImageField source="artwork" />
                    <TextField source="name" />
                    <TextField source="description" />
                    <ChipField source="genres" />
                    <TextField source="year" />
                    <PlaylistArtistList source={"artists"} reference={"artists"} />
                    <TextField source="status" />
                </Tab>
                {/* <Tab label="tracks" path="tracks">
                    <ReferenceArrayField reference="tracks" target="tracks" addLabel={false}>
                        <TrackList />
                    </ReferenceArrayField>
                </Tab> */}
            </TabbedShowLayout>
        </Show>
    )
};