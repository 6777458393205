import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';
import Theme from '../Theme';
const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        color: Theme.palette.primary.light
    },
};

const LinkToTarget = ({ field, classes, record, reference, prefix, searchField }) => {
    // console.log("LINK TO THE ARTIST PAGE ", { field, classes, record, reference, prefix, searchField });
    if (reference === "artist") {
        reference = "artists";
    };
    return (
    <Button
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/' + reference,
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ [searchField || "name"]: record[field + prefix || "_name"] }),
            }),
        }}
        className={classes.link}
    >
        {/* <Icon className={classes.icon} /> */}
        {/* {translate('resources.categories.fields.products')} */}
        {record[field + "_name"]}
    </Button>
)};

const enhance = compose(
    withStyles(styles),
    translate
);
export default enhance(LinkToTarget);