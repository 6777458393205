import React from "react";
import { Query, Loading, TextField } from "react-admin";
import Items from "./Items";
import QuerySection from "./QuerySection";

export default ({ sectionID }) => {
  return (
    <Query type="GET_ONE" resource="featured" payload={{ id: sectionID }}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error) return <p> </p>;
        let content;
        switch (data.type) {
          case "items":
            content = <Items ids={data.items} resource={data.target} mini />;
            break;
          case "query":
            content = <QuerySection record={data} />;
            break;
          default:
            content = <div></div>;
            break;
        }
        return (
          <div>
            <TextField text={false} record={data} source="title" />
            {content}
          </div>
        );
      }}
    </Query>
  );
};
