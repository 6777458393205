export const pluralize = (val, word, plural = word + 's') => {
    const _pluralize = (num, word, plural = word + 's') =>
        [1, -1].includes(Number(num)) ? word : plural;
    if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
    return _pluralize(val, word, plural);
};

export const singular = (text) => {
    let title = text
    title = title.charAt(0).toUpperCase() + title.substring(1)
    const last = title.charAt(title.length - 1)
    if (last === "s") {
        title = title.substring(0, title.length - 1)
    }
    return title
}