// import React from 'react'
import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
    palette: {
        dark: '#000',
        type: 'dark',
        primary: {
            main: '#FFA700',
        },
        secondary: {
            main: '#FFA700',
        },
        text: {
            // secondary: "#000",

        }

    },
});