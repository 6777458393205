import React from "react";
//eslint-disable-next-line
import { Query, Loading, ImageField, EditButton } from "react-admin";
import { Typography, Grid } from "@material-ui/core";

const Items = ({ data, style, record, classes, props }) => {
  return (
    <React.Fragment>
      {data.map((item) => (
        <Grid
          key={item.id}
          item
          direction="column"
          justify="center"
          alignItems="center"
        >
          {item.pictures ? (
            <ImageField
              className="size"
              source={item.image ? "image" : "artwork"}
              title="title"
              record={item}
              style={{
                borderRadius: 5,
                ...style,
              }}
            />
          ) : (
            <ImageField
              className="size"
              source={item.image ? "image" : "artwork"}
              title="title"
              record={item}
              style={{
                ...style,
              }}
            />
          )}
        </Grid>
      ))}
    </React.Fragment>
  );
};

const QuerySection = ({ record, resource, itemStyle, mini }) => {
  const style = itemStyle ? itemStyle : {};
  const { q } = record;
  let filter = {};
  if (q) {
    q.forEach((qq) => {
      filter[qq.name] = qq.val;
    });
  }
  let targetResource = record.target;
  if (targetResource === "utility") {
    targetResource = "utilities";
  }
  if (targetResource === "banner") {
    targetResource = "banners";
  }
  if (targetResource === "video") {
    targetResource = "videos";
  }

  return (
    <Query
      type="GET_LIST"
      resource={targetResource}
      payload={{
        filter,
        sort: { id: "DESC" },
        pagination: { page: 1, perPage: 10 },
      }}
    >
      {(props) => {
        if (props.loading)
          return <Loading label={`${record.name} is being retrieved`} />;
        if (props.error) return <p>THERE WAS AN ERROR</p>;
        if (mini) {
          return (
            <Grid
              container
              style={{ borderRadius: 10, padding: 10 }}
              alignItems="center"
            >
              <Items
                style={style}
                record={record}
                resource={resource}
                {...props}
              />
            </Grid>
          );
        }
        return (
          <Grid>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <Typography
                  variant="headline"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  {record.name}
                </Typography>
              </Grid>
              <EditButton record={record} basePath="/featured" />
            </Grid>
            <Grid
              container
              style={{ backgroundColor: "#000", borderRadius: 10, padding: 10 }}
              alignItems="center"
            >
              <Items
                style={style}
                record={record}
                resource={resource}
                {...props}
              />
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};

export default QuerySection;
