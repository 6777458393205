import React from 'react'

import { ImageField, ReferenceField, TextField, FunctionField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    container: {
        paddingVertical: theme.spacing.unit,
    },
    image: {

    },
    spacer: {
        flex: 1,
    },
});

export default withStyles(styles)((props) => {
    if (props.record.targetID) {
        // props.record.artwork = "https://api.udux.com/api/v1/thumbnailer/100x100/" + props.record.artwork
        return (<ReferenceField {...props} source={"targetID"} reference={props.record.target}>
            <FunctionField label="Content" render={record => {
                return (<Grid
                    container
                    className={props.classes.container}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <ImageField record={record} source="artwork" title="name" className={props.classes.image} />
                    <TextField record={record} source="name" />
                </Grid>)
            }} />
        </ReferenceField>)
    }
    return <span />
})