import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  FormDataConsumer,
  Create,
  DateField,
  Responsive,
  SimpleList,
  CardActions,
  SelectInput,
  BooleanField,
  EditButton,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  CloneButton,
  REDUX_FORM_NAME,
} from "react-admin";
import { TextInput, Filter } from "react-admin";
import Pagination from "../../Pagination";
import { singular } from "../../Libs/Pluralize";
import StatusInput from "../../Input/StatusInput";
import { DateTimeInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import PushArtwork from "../../Fields/PushArtwork";
import { Button, Grid } from "@material-ui/core";
import { change } from "redux-form";
import SendPush from "../../Buttons/SendPush";

const PushTitle = ({ record, action }) => {
  return (
    <span>
      {action ? action : "Edit"} Push{" "}
      {record && record.title ? `"${record.title}"` : ""}
    </span>
  );
};

const dateFormatter = (v) => {
  if (!(v instanceof Date) || isNaN(v)) return;
  return v;
};

const PushEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <SendPush basePath={basePath} record={data} />
    {/* Add your custom actions */}
    <CloneButton basePath={basePath} record={data} />
  </CardActions>
);

const PushFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Title" source="title" />
    <ReferenceInput
      label="Album"
      source="targetID"
      reference="albums"
      filterToQuery={(searchText) => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optiontext="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Artist"
      source="targetID"
      reference="artists"
      filterToQuery={(searchText) => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optiontext="name" />
    </ReferenceInput>
    <StatusInput label="Status" source="status" />
    <DateTimeInput
      label="Send Time"
      source="scheduleAt"
      format={dateFormatter}
      providerOptions={{ utils: MomentUtils }}
    />
    <DateTimeInput
      label="Created Time"
      source="created"
      format={dateFormatter}
      providerOptions={{ utils: MomentUtils }}
    />
  </Filter>
);

export const TargetField = (props) => {
  return (
    <ReferenceField
      label="Target"
      {...props}
      source={"targetID"}
      reference={props.record.target}
      linkType="show"
    >
      <TextField source="name" />
    </ReferenceField>
  );
  // return <LinkToItem field="targetID" reference={props.record.target} label="" />
};

export const PushList = (props) => (
  <List {...props} pagination={<Pagination />} filters={<PushFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => `${record.target}`}
          tertiaryText={(record) =>
            new Date(record.scheduleAt).toLocaleDateString()
          }
        />
      }
      medium={
        <Datagrid>
          <BooleanField source="scheduled" />
          <TextField source="target" label="Content Type" />
          <PushArtwork {...props} />
          {/*<TextField source="targetID" /> */}
          {/* <TargetField label="Target" /> */}
          {/* <TextField source="topic" /> */}
          <TextField source="title" />
          <TextField source="description" />
          <DateField source="scheduleAt" label="Send Time" showTime />
          <DateField source="created" label="Created" showTime />
          <TextField source="status" />
          <SendPush />
          <EditButton />
          <CloneButton />
        </Datagrid>
      }
    />
  </List>
);
const queryForTarget = (target, searchText) => {
  switch (target) {
    case "playlists":
      return { q: searchText };

    default:
      return { q: searchText };
  }
};

const redirect = (basePath, id, data) => `/push`;

const form = (extraFields) => (
  <SimpleForm redirect={redirect}>
    <SelectInput
      source="topic"
      defaultValue="general"
      choices={[{ id: "general", name: "General" }]}
    />
    <TextInput source="title" isRequired />
    {/* <RichTextInput source="body" /> */}
    {/* <TinyMCEInput source="title" config={{ skin: false }} required /> */}
    <TextInput source="description" isRequired />
    <DateTimeInput
      source="scheduleAt"
      label="When to send?"
      providerOptions={{ utils: MomentUtils }}
    />
    <FormDataConsumer>
      {({ formData, dispatch }) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(change(REDUX_FORM_NAME, "scheduleAt", null));
            }}
          >
            Clear Date
          </Button>
        );
      }}
    </FormDataConsumer>
    <SelectInput
      label="Push notification type (track, album ...)"
      source="target"
      choices={[
        { id: "albums", name: "Album" },
        { id: "artists", name: "Artist" },
        { id: "moods", name: "Mood" },
        { id: "playlists", name: "Playlist" },
        { id: "tracks", name: "Track" },
        { id: "videos", name: "Video" },
      ]}
      isRequired
    />
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (!formData.target) return <span></span>;
        if (formData.target === "videos")
          return <span style={{ paddingVertical: 10 }}></span>;
        let title = singular(formData.target);
        // console.log(formData)
        return (
          <Grid>
            <ReferenceInput
              filterToQuery={(searchText) =>
                queryForTarget(formData.target, searchText)
              }
              label={`Search for ${title}`}
              source={"targetID"}
              reference={formData.target}
            >
              <AutocompleteInput
                optionText={(choice) => {
                  if (formData.target === "moods") return choice.name;
                  if (formData.target === "playlists") return choice.name;
                  if (choice.album_name) {
                    return `${choice.artist_name} - ${choice.album_name} -   ${choice.name}`;
                  } else if (choice.artist_name) {
                    return `${choice.artist_name} - ${choice.name}`;
                  }
                  return `${choice.artist_name + " - " || ""}${
                    choice.album_name + " - " || ""
                  }${choice.name || choice.title || choice.id || "unknown"}`;
                }}
              />
            </ReferenceInput>
            {formData.targetID && (
              <Grid alignContent="flex-start" direction="row-reverse">
                <PushArtwork
                  {...rest}
                  source={"targetID"}
                  reference={formData.target}
                  record={formData}
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    </FormDataConsumer>
    {extraFields && extraFields}
  </SimpleForm>
);
export const PushEdit = (props) => (
  <Edit {...props} title={<PushTitle />} actions={<PushEditActions />}>
    {form()}
  </Edit>
);

export const PushCreate = (props) => (
  <Create {...props} title={<PushTitle action="Create" subtitle="" />}>
    {form()}
  </Create>
);
