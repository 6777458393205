import React, { useState } from "react";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import firebase from "firebase";
import Theme from "../../Theme";
import compose from "recompose/compose";
import { withStyles, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import { addField, ImageField } from "react-admin";
import sha256 from "js-sha256";

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
    overflowX: "auto",
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
  },
});

const enhance = compose(
  addField,
  withStyles(styles),
  connect(() => {
    return {};
  })
);
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export default enhance(
  ({ record, source, classes, label, accept, input, ...rest }) => {
    const [artwork, setArtwork] = useState(record[source]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const staticPath = `/`;
    const storageRef = firebase
      .storage()
      .refFromURL(
        `gs://${process.env.REACT_APP_IMAGE_STORAGE_BUCKET}${staticPath}`
      );

    const handleUploadStart = () => {
      setUploading(true);
      setProgress(0);
    };
    const handleProgress = (progress) => {
      setProgress(progress);
    };
    const handleUploadError = (error) => {
      setUploading(false);
      // console.error("upload error", error);
      window.alert("Upload failed");
    };

    const handleUploadSuccess = async (filename) => {
      const url = `https://${process.env.REACT_APP_IMAGE_STORAGE_BUCKET}${staticPath}${filename}`;
      input.onChange(url);
      setArtwork(url);
      setUploading(false);
    };

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Grid container>
            {artwork && (
              <ImageField source="artwork" title="title" record={{ artwork }} />
            )}
            <LinearProgress
              color="secondary"
              variant="determinate"
              value={progress}
              style={{ width: "100%", marginBottom: 10 }}
            />
            <Grid container alignItems="center" justify="center">
              <CustomUploadButton
                accept={accept}
                name={`${record.id}.temp`}
                storageRef={storageRef}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                onProgress={handleProgress}
                filename={(file) => {
                  return sha256(
                    // file.name.split(".")[0] + (user ? user.id : "")
                    uuidv4()
                  );
                }}
                style={{
                  backgroundColor: Theme.palette.primary.dark,
                  color: Theme.palette.common.black,
                  padding: 10,
                  borderRadius: 4,
                }}
              >
                {uploading ? "Uploading" : "Upload Image"}
              </CustomUploadButton>
            </Grid>
          </Grid>
        </Paper>
        <p>{label}</p>
      </React.Fragment>
    );
  }
);
