import React from 'react';
import { FormDataConsumer } from 'react-admin';
import UnixDateTimePicker from './UnixDateTimePicker';
import { Grid } from '@material-ui/core';

export const ShareAndWinInput = props => {
    return <FormDataConsumer {...props}>
        {({ formData }) => {
            if (formData && formData.isPromo && formData.isPromo === true) {
                return <Grid container direction="row" style={{ width: "1.2em" }}>
                    <Grid>
                        <UnixDateTimePicker {...props} source="startDate" label="Promo Start Time" options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />
                    </Grid>
                    <Grid>
                        <UnixDateTimePicker {...props} source="endDate" label="Promo End Time" options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />
                    </Grid>
                </Grid>
            }
            return null
        }}
    </FormDataConsumer>
}