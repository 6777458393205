import React from 'react';

const DurationField = (props) => {
    let timeString
    try {
        var date = new Date(null);
        date.setSeconds(props.record.duration); // specify value for SECONDS here
        timeString = date.toISOString().substr(11, 8);
    } catch (error) {
        timeString = props.record.duration
    }
    return <span>{timeString}</span>
}

DurationField.defaultProps = { label: 'Duration' };
export default DurationField