import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FormDataConsumer,
  ReferenceField,
  ImageField,
  EditButton,
  DateField,
  DeleteWithConfirmButton,
  ChipField,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanInput,
  BooleanField,
  ReferenceManyField,
  ReferenceInput,
  SelectInput,
  TextInput,
  Filter,
  FunctionField,
} from "react-admin";
import { TrackDataGrid } from "../Track/TrackList";
import Pagination from "../../Pagination";
import RelatedTracks from "../../Buttons/RelatedTracks";
import LinkToItem from "../../Input/LinkToItem";
import "./album.css";
import { DateInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import CopyToClipboardField from "../../Fields/CopyToClipboardField";

const choices = [
  { _id: "active", name: "Active" },
  { _id: "inactive", name: "InActive" },
  { _id: "error", name: "Error" },
];

const dateFormatter = (v) => {
  // console.log(v)
  if (!(v instanceof Date) || isNaN(v)) return new Date(v);
  return v;
};

const dateParser = (v) => {
  const d = new Date(v);
  if (isNaN(d)) return;
  return d.getTime();
};

const AlbumsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Name" source="name" allowEmpty />
    <BooleanInput source="isPromo" label="Share and Win" allowEmpty />
    <ReferenceInput
      label="Artist"
      source="artist"
      reference="artists"
      allowEmpty
    >
      <SelectInput optiontext="name" />
    </ReferenceInput>
    <ReferenceInput label="CP" source="cp" reference="cp" allowEmpty>
      <SelectInput optiontext="name" />
    </ReferenceInput>
    <TextInput label="UPC" source="upc" allowEmpty />
    <DateField source="created" label="Created" showTime />
    <TextInput label="Genre" source="genre_name" allowEmpty />
    <SelectInput
      source="status"
      choices={choices}
      optionText="name"
      optionValue="_id"
    />
    <TextInput label="Year" source="year" allowEmpty />
    {/* <UnixDateTimePicker label="Release Date" source="releaseDate" allowEmpty /> */}
    <DateInput
      label="Release Date"
      source="releasedate"
      defaultValue={new Date()}
      parse={dateParser}
      format={dateFormatter}
      options={{ format: "DD/MM/YYYY" }}
      providerOptions={{ utils: MomentUtils }}
    />
    <DateInput
      label="Created Date"
      source="createdDate"
      defaultValue={new Date()}
      parse={dateParser}
      format={dateFormatter}
      options={{ format: "DD/MM/YYYY" }}
      providerOptions={{ utils: MomentUtils }}
    />
  </Filter>
);

export default (props) => {
  return (
    <List filters={<AlbumsFilter />} {...props} pagination={<Pagination />}>
      <Datagrid>
        <CopyToClipboardField field="sharelink" />
        <TextField source="streams" label="Streams" />
        <TextField source="android" label="Android" />
        <TextField source="ios" label="iOS" />
        <TextField source="web" label="Web" />
        {/* <BooleanField label="Share and Win Eligible" source="isPromo" /> */}
        <FormDataConsumer label="Artwork">
          {({ formData, ...rest }) =>
            formData.artwork ? (
              <ImageField
                className="size"
                label="Artwork"
                source="artwork"
                {...rest}
              />
            ) : (
              <ImageField
                className="size"
                label="Artwork"
                source="image"
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <TextField label="UPC" source="upc" />
        <TextField label="Album Name" source="name" />
        <LinkToItem field="artist" reference="artist" label="Artist Name" />
        <FunctionField
          source="genre_name"
          label="Genre"
          render={(record) => `${record.genre_name || record.genre}`}
        />
        <ChipField source="albumtype" label="Album Type" />
        <DateField showTime source="releasedate" label="Release Date" />
        <TextField source="year" />
        <TextField source="label" />
        <ReferenceField label="CP" source="cp" reference="cp">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="tracks_count"
          label="Track Count"
          render={(record) =>
            `${record.track_count || record.tracks_count || ""}`
          }
        />
        <ChipField source="status" />
        <RelatedTracks field="album" />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export const AlbumsShow = (props) => {
  return (
    <Show filters={<AlbumsFilter />} {...props}>
      <TabbedShowLayout>
        <Tab label="DETAILS">
          <DateField showTime source="releasedate" label="Release Date" />
          <ImageField source="artwork" />
          <TextField source="name" />
          <ReferenceField source="artist" reference="artists">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="description" />
          <ChipField source="genres" />
          <TextField source="year" />
        </Tab>
        <Tab label="tracks" path="tracks">
          <ReferenceManyField
            reference="tracks"
            target="album"
            addLabel={false}
          >
            <TrackDataGrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="META">
          <ReferenceField label="Created" source="cp" reference="cp">
            <TextField source="created" />
          </ReferenceField>
          <ReferenceField label="Modified" source="cp" reference="cp">
            <BooleanField source="modified" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
