import React from 'react'

import { List, Datagrid, TextField, ReferenceField, ImageField, FunctionField } from 'react-admin'
import Pagination from '../../Pagination'
import moment from 'moment'

const TargetInfo = (props) => {
    console.log(props)
    return <ReferenceField source={"targetID"}
        reference={props.record.target} {...props}>
        {props.children}
    </ReferenceField>
}
export const PromolinkList = props => (
    <List {...props} pagination={<Pagination />}>
        <Datagrid  >
            <FunctionField label="Created" render={record => {
                const date = moment(record.created)
                return date.format("LLL")
            }} />
            <TextField source="target" label="Promo Content Type" />
            <TargetInfo label="Artwork">
                <ImageField source={"artwork"} />
            </TargetInfo>
            <TargetInfo label="Name">
                <TextField source={"name"} />
            </TargetInfo>
            <TargetInfo label="Artist">
                <TextField source={"artist_name"} />
            </TargetInfo>
            <TextField source="owner" />
            <ReferenceField source="owner" reference="user">
                <TextField source="email" />
            </ReferenceField>
            <TextField source="sharelink" />
        </Datagrid>
    </List>
);