import React from 'react';
import {
    List, Datagrid, TextField, ImageField, EditButton, ShowButton
} from 'react-admin';
import { TextInput, Filter } from 'react-admin';
import Pagination from '../../Pagination';


export const GenresFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="Name" source="name" allowEmpty />
    </Filter>
);


export const GenresList = props => (
    <List filters={<GenresFilter />} {...props} pagination={<Pagination />}>
        <Datagrid rowClick="edit">
            <ImageField className="size" source="artwork" title="artwork" />
            <TextField source="name" />
            <TextField source="description" />
            <EditButton label="Edit" />
            <ShowButton label="Show" />
        </Datagrid>
    </List>
);