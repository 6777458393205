import React from "react";
import { SelectArrayInput, TextField } from "react-admin";

const choices = [
  { id: "spc_nigeria", name: "Nigeria", symbol: "NG" },
  { id: "spc_worldwide", name: "World", symbol: "World" },
  { id: "spc_gaht", name: "Habari Authorized", symbol: "Habari" },
];

export default (props) => {
  return (
    <SelectArrayInput
      {...props}
      source="perms"
      label="Permissions"
      choices={choices}
      optionText="name"
      optionValue="id"
    />
  );
};

export const PermissionsField = (props) => {
  if (props.record.perms !== undefined) {
    let textFieldsOptions = props.record.perms;

    textFieldsOptions.reduce((acc, value) => {
      choices.filter((choice) => choice.id === value);
      return acc;
    }, []);

    return <TextField source="perms" />;
  } else {
    return <TextField />;
  }
};
