import React from "react";
import compose from "recompose/compose";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/icons/LibraryMusic";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import { stringify } from "query-string";
import Theme from "../Theme";

const styles = {
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
    color: Theme.palette.primary.light
  }
};

const LinkToRelatedProducts = ({ field, classes, record, translate }) => (
  <Button
    size="small"
    color="primary"
    component={Link}
    to={{
      pathname: "/tracks",
      search: stringify({
        page: 1,
        perPage: 25,
        sort: "id",
        order: "DESC",
        filter: JSON.stringify({ [field]: record.id })
      })
    }}
    className={classes.link}
  >
    <Icon className={classes.icon} />
    {/* {translate('resources.categories.fields.products')} */}
    Tracks
  </Button>
);

const enhance = compose(withStyles(styles), translate);
export default enhance(LinkToRelatedProducts);
