import React from "react";
import {
  List,
  FunctionField,
  Datagrid,
  ShowButton,
  TextField,
  ImageField,
  ChipField,
  EditButton,
  BooleanField,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  DateField,
} from "react-admin";
import { TextInput, Filter } from "react-admin";
import Pagination from "../../Pagination";
import { ReferenceField } from "ra-ui-materialui/lib/field";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import { DateInput } from "react-admin-date-inputs";
import CopyToClipboardField from "../../Fields/CopyToClipboardField";

const choices = [
  { _id: "active", name: "Active" },
  { _id: "inactive", name: "InActive" },
  { _id: "error", name: "Error" }
];

const dateFormatter = v => {
  // console.log(v)
  if (!(v instanceof Date) || isNaN(v)) return new Date(v);
  return v;
};

const dateParser = v => {
  const d = new Date(v);
  if (isNaN(d)) return;
  return d.getTime();
};


const PlaylistsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Name" source="name" />
    <BooleanInput source="isPromo" label="Share and Win" allowEmpty />
    <BooleanInput source="public" label="IsPublic" allowEmpty />
    <ReferenceInput source="cp" reference="cp" perPage={100}>
      <SelectInput optionText={v => (v.name ? v.name : v.id)} />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={choices}
      optionText="name"
      optionValue="_id"
    />
    <DateInput
      label="Created Date"
      source="createdDate"
      defaultValue={new Date()}
      parse={dateParser}
      format={dateFormatter}
      options={{ format: "DD/MM/YYYY" }}
      providerOptions={{ utils: MomentUtils }}
    />
  </Filter>
);
export default props => {
  // console.log("THE PLAYLIST", props);
  return (
  <List filters={<PlaylistsFilter />} {...props} pagination={<Pagination />}>
    <Datagrid rowClick="edit">
        <CopyToClipboardField field="sharelink" />
      <ImageField className="size" source="artwork" title="artwork" />
      <TextField source="name" />
      {/* <TextField source="description" /> */}
      <BooleanField source="public" />
      {/* <TextField source="admin" /> */}
      <FunctionField
        label="Track Count"
        render={record => {
          if (record.tracks) return Object.keys(record.tracks).length;
          return 0;
        }}
      />
      <ReferenceField label="Owner" source="owner" reference="user">
        <TextField source="firstName" /> 
      </ReferenceField>
      <ReferenceField source="cp" reference="cp">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created" label="Created" showTime />
      <ChipField source="status" />
      <ShowButton label="Show" />
      <EditButton label="Edit" />
      {/* <DeleteButton label="Delete" /> */}
    </Datagrid>
  </List>
)};