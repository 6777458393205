import React from "react";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import StatusInput from "../../Input/StatusInput";
import PermissionsInput from "../../Input/PermissionsInput";
import LinkOptionText from "../../Libs/LinkOptionText";
import FileInput from "../../Input/FileInput";
import { Grid } from "@material-ui/core";

export const EditFields = () => {
  return (
    <Grid container direction="column">
      <TextInput source="title" label="Banners Title" />
      <TextInput source="subtitle" label="Banners Subtitle" />
      <SelectInput
        label="Banner Entity"
        source="link"
        choices={[
          { id: "#/album_detail/", name: "Album" },
          { id: "#/artist_detail/", name: "Artist" },
          { id: "#/playlist_detail/", name: "Playlist" },
          { id: "#/track_detail/", name: "Track" },
          { id: "#/mood_detail/", name: "Moods" },
          { id: "#/video_detail/", name: "Video" },
          { id: "#/live_detail/", name: "Livestream" },
        ]}
      />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.link) {
            const name = formData.link.split("_detail/")[0].split("#/")[1];
            if (name !== "live") {
              return (
                <ReferenceInput
                  label={`Link to ${name}`}
                  source="entityId"
                  reference={name + "s"}
                  key={formData.link}
                >
                  <AutocompleteInput
                    optionText={(choice) => LinkOptionText(choice, name)}
                  />
                </ReferenceInput>
              );
            }
          }
          return <></>;
        }}
      </FormDataConsumer>
      <StatusInput />
      <PermissionsInput />
      <ReferenceInput
        source="cp"
        reference="cp"
        filterToQuery={(searchText) => ({
          name: searchText,
          status: "active",
        })}
        perPage={100}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
  );
};

export const EditSections = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={5} style={{ marginRight: "45px" }}>
        <EditFields {...props} />
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid>
          <FileInput
            {...props}
            source="mobile_artwork"
            label="Mobile Banner - Recommended size 300 x 318"
            accept="image/*"
          />
        </Grid>
        <Grid>
          <FileInput
            {...props}
            source="desktop_artwork"
            label="Desktop Banner - Recommended size 962 x 318"
            accept="image/*"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
