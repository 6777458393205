import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// / a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    borderRadius: 10,
    margin: isDragging ? 10 : `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'rgb(113, 97, 97)' : '#000',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(113, 97, 97)' : '#000',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

export default class Reorderable extends Component {
    static defaultProps = {
        direction: "horizontal"
    }
    constructor(props) {
        super(props)
        const choices = props.choices
        this.state = {
            items: choices,
            dragEnd: false,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragStart = () => {
        this.setState({
            dragEnd: true,
        })
    }
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        // this.props.input.onChange(items.map(v => v.id))
        this.setState({
            items,
            dragEnd: true
        });
        this.props.onChange && this.props.onChange(items)
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                <Droppable droppableId="droppable" direction={this.props.direction}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {this.state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {this.props.renderItem(item)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}