import React from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  Create,
  BooleanInput,
  addField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ImageField,
} from "react-admin";
import StatusInput from "../../Input/StatusInput";
import OrderableContentList from "../../Components/OrderableContentList";
import { Grid } from "@material-ui/core";
import { ShareAndWinInput } from "../../Input/ShareAndWin";
import PermissionsInput from "../../Input/PermissionsInput";
import FileInput from "../../Input/FileInput";
let style = {
  marginRight: "45px",
};

const PlaylistsTitle = ({ record }) => {
  return <span>Modify Playlist {record ? `"${record.name}"` : ""}</span>;
};

const TrackEdit = addField(OrderableContentList, { contentResource: "tracks" });

const EditFields = (props) => {
  return (
    <Grid container direction="column">
      {/* <ImageInput source="artwork" label="Artwork" accept="image/*">
          <ImageField source="artwork" title="title" />
        </ImageInput> */}
      <FileInput
        {...props}
        source="artwork"
        label="Recommended size 300 x 318"
        accept="image/*"
      />
      <TextInput {...props} source="name" />
      <TextInput {...props} source="description" />
      <StatusInput {...props} label="Status" />
      <PermissionsInput />
      <BooleanInput {...props} source="public" />
      <BooleanInput source="isPromo" label="Share and Win Eligible" />
      <ShareAndWinInput />
    </Grid>
  );
};

const EditSections = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={3} style={style}>
        <EditFields {...props} />
      </Grid>
      <Grid item xs={12} md={7}>
        <TrackEdit {...props} source="tracks" />
      </Grid>
    </Grid>
  );
};
export const PlaylistsEdit = (props) => (
  <Edit title={<PlaylistsTitle />} {...props}>
    <SimpleForm>
      <EditSections />
    </SimpleForm>
  </Edit>
);

export const PlaylistsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput source="artwork" label="Artwork" accept="image/*">
        <ImageField source="artwork" title="title" />
      </ImageInput>
      {/* <TextInput source="title" /> */}
      <TextInput source="name" />
      <TextInput source="description" />

      <ReferenceInput label="CP" source="cp" reference="cp" allowEmpty>
        <SelectInput optiontext="name" />
      </ReferenceInput>
      <StatusInput label="Status" />
      <PermissionsInput />
      <BooleanInput source="public" />
    </SimpleForm>
  </Create>
);
