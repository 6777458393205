import React from 'react';
import { SimpleForm, TextInput, Edit } from 'react-admin';
import MailInput from './MailInput';


const MailTitle = ({ record }) => {
    return <span>Mail Template - {record ? `"${record.name}"` : ''}</span>;
};
export default props => (
    <Edit title={<MailTitle />}  {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <MailInput source="content" />
        </SimpleForm>
    </Edit>
);

