import React from "react";
import ReactPlayer from "react-player";

export const TrackPlayer = ({ source, playing, onEnded }) => {
  return (
    <React.Fragment>
      <ReactPlayer
        width={0}
        height={0}
        playsinline
        url={source}
        playing={playing}
        onEnded={onEnded}
      />
    </React.Fragment>
  );
};

TrackPlayer.defaultProps = {
  onEnded: () => {},
};
