import React from 'react'
import { List, Datagrid, EditButton, TextField, ImageField, TextInput, SimpleForm, Edit, Create } from 'react-admin'
import StatusInput from '../../Input/StatusInput'
import Pagination from '../../Pagination';
import { ColorInput } from 'react-admin-color-input';


export const UtilityList = props => (
    <List {...props} pagination={<Pagination />}>
        <Datagrid rowClick="edit">
            <ImageField source="artwork" />
            <TextField source="name" />
            <TextField source="title" />
            <TextField source="hex" />
            <TextField source="link" />
            <TextField source="status" />
            <EditButton />
        </Datagrid>
    </List>
);
export const UtilityEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="artwork" />
            <TextInput source="name" />
            <TextInput source="title" />
            <ColorInput source="hex" label="hex"/>
            <TextInput source="link" />
            <StatusInput />
        </SimpleForm>
    </Edit>
);
export const UtilityCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="artwork" />
            <TextInput source="name" />
            <TextInput source="title" />
            <ColorInput source="hex" label="Hex"/>
            <TextInput source="link" />
            <StatusInput />
        </SimpleForm>
    </Create>
);