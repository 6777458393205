/* eslint-disable no-lone-blocks */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    Button,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    REDUX_FORM_NAME
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';

import { crudGetMany } from 'ra-core';
import { selectTitle } from '../Libs/Contstants';

class AddFeaturedItem extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        // this.setState({ showDialog: true });
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('select-content');
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
    }
    handleSubmit = values => {
        const { change, showNotification, formData, target } = this.props;
        const field = target.substring(0, target.length - 1);
        const selectedValue = values[field]
        if (selectedValue) {
            if (formData.items && formData.items.includes(selectedValue)) {
                showNotification('This item already exists in the list');
                return
            }
            // fetchStart();
            crudGetMany(target, [selectedValue])
            change(REDUX_FORM_NAME, 'items', [selectedValue, ...(formData.items ? formData.items : [])]);
            // this.props.dispatch(reset("select-content")) 

            // Trigger a submit of our custom quick create form
            // This is needed because our modal action buttons are oustide the form
            submit('select-content');
        } else {
            showNotification("Please select " + selectTitle[target], 'info');
        }
    };
    queryForTarget = (target, searchText) => {
        switch (target) {
            case "playlists":
                return { name: searchText, public: true }

            default:
                return { name: searchText }
        }
    }
    render() {
        console.log("ADD FEATURED ITEM", this.props);
        console.log("THE CURRENT STATE ", this.state);
        const { target } = this.props;

        const onlyBanners = target === "banners" ? (
             <SelectInput resettable optionText={(choice) => {
                if (choice.album_name) {
                    return `${choice.artist_name} - ${choice.album_name} - ${choice.name}`
                } else if (choice.artist_name) {
                    return `${choice.artist_name} - ${choice.name}`
                }
                return `${choice.artist_name || ""} - ${choice.album_name || ""} - ${choice.name || choice.title || choice.id || "unknown"} - ${choice.subtitle || ""}`
            }} />
        ) : (
            <AutocompleteInput optionText={(choice) => {
                if (choice.album_name) {
                    return `${choice.artist_name} - ${choice.album_name} - ${choice.name}`
                } else if (choice.artist_name) {
                    return `${choice.artist_name} - ${choice.name}`
                }
                return `${choice.artist_name || ""} - ${choice.album_name || ""} - ${choice.name || choice.title || choice.id || "unknown"}`
            }} />
        )

        const field = target.substring(0, target.length - 1);
        return (
            <Fragment>
                <div>
                    <SimpleForm
                        form="select-content"
                        resource={target}
                        // We override the redux-form onSubmit prop to handle the submission ourselves
                        onSubmit={this.handleSubmit}
                        // We want no toolbar at all as we have our modal actions
                        toolbar={null}
                    >
                        <ReferenceInput
                            filterToQuery={(searchText) => ({ q: searchText, target })}
                            label={`Select ${selectTitle[target]} to add`} source={field} reference={target}>
                            {onlyBanners}
                        </ReferenceInput>
                        <Button onClick={this.handleClick} label="Add Selected" style={{ justify: "flex-start" }}>
                            <IconContentAdd />
                        </Button>
                    </SimpleForm>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('select-content')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
    AddFeaturedItem
);