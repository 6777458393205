export default (choice, target) => {
  switch (target) {
    case "album":
      return `${choice.artist_name} - ${choice.name}`;
    case "track":
      return `${choice.artist_name} - ${choice.album_name} - ${choice.name}`;
    case "video":
      return `${choice.name}`;
    case "playlist":
      return `${choice.name}`;
    case "mood":
      return `${choice.name}`;

    default:
      return `${choice.name}`;
  }
};
