export const entityChoices = [
    { id: "albums", name: "Album" },
    { id: "artists", name: "Artist" }, 
    { id: "tracks", name: "Track" }
    , { id: "playlists", name: "Playlist" }
    , { id: "utilities", name: "Utility Belt" }
    , { id: "events", name: "Event" }
    , { id: "comments", name: "Comment" }
    , { id: "feeds", name: "Feed" }
    , { id: "banners", name: "Banner" }
    , { id: "moods", name: "Mood" }
    , { id: "videos", name: "Video" }]