import React from 'react'
import { Button } from "ra-ui-materialui"
import { withStyles } from '@material-ui/core/styles';
import Play from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import { CircularProgress } from '@material-ui/core';
import Theme from '../Theme';
const styles = {
    progress: {
        margin: Theme.spacing.unit * 2,
    },
}

export default withStyles(styles)(({
    classes,
    record,
    label,
    iconProps = {},
    currentTrack = null,
    loading = false,
    playing = true,
    icon = <Play {...iconProps} />, 
    iconPause = <Pause {...iconProps} />,
    onClick = () => { }
}) => {
    // console.log("RECORD RECIEVED FROM PLAYERBUTTON ", record);
    return (
        <Button label={label} onClick={() => onClick(record)}>
            {(currentTrack && currentTrack.id === record.id) ?
                loading ? <CircularProgress className={classes.progress}  {...iconProps} />
                    : !playing ? icon : iconPause
                : icon
            }
        </Button>
    )
})