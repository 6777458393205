import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ImageField,
  EditButton,
  DateField,
  DeleteWithConfirmButton,
  ChipField,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  ReferenceManyField,
} from "react-admin";
import { ReferenceInput, SelectInput, TextInput, Filter } from "react-admin";
import { TrackDataGrid } from "../Track/TrackList";
import Pagination from "../../Pagination";
import StatusInput from "../../Input/StatusInput";
import UnixDateTimePicker from "../../Input/UnixDateTimePicker";

const VideosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Name" source="name" allowEmpty />
    <ReferenceInput
      label="Artist"
      source="artist"
      reference="artists"
      allowEmpty
    >
      <SelectInput optiontext="name" />
    </ReferenceInput>
    <TextInput label="Genre" source="genres" allowEmpty />
    <StatusInput label="Status" allowEmpty />
    <TextInput label="Year" source="year" allowEmpty />
    <UnixDateTimePicker label="Release Date" source="releaseDate" allowEmpty />
  </Filter>
);

export default (props) => (
  <List filters={<VideosFilter />} {...props} pagination={<Pagination />}>
    <Datagrid>
      <DateField showTime source="releasedate" label="Release Date" />
      <ImageField className="size" source="artwork" title="artwork" />
      <TextField source="name" />
      <ChipField source="status" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export const VideosShow = (props) => {
  return (
    <Show filters={<VideosFilter />} {...props}>
      <TabbedShowLayout>
        <Tab label="DETAILS">
          <DateField showTime source="releasedate" label="Release Date" />
          <ImageField source="artwork" />
          <TextField source="name" />
          <ReferenceField source="artist" reference="artists">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="description" />
          <ChipField source="genres" />
          <TextField source="year" />
        </Tab>
        <Tab label="tracks" path="tracks">
          <ReferenceManyField
            reference="tracks"
            target="album"
            addLabel={false}
          >
            <TrackDataGrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="META">
          <ReferenceField label="Created" source="cp" reference="cp">
            <TextField source="created" />
          </ReferenceField>
          <ReferenceField label="Modified" source="cp" reference="cp">
            <BooleanField source="modified" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
