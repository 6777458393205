import React from 'react';
import { Query, Loading } from 'react-admin';
import ItemsSection from './ItemsSection';
import QuerySection from './QuerySection';

export default ({
    sectionID,
    itemStyle, }) => {
    return <Query type="GET_ONE" resource="featured" payload={{ id: sectionID }}>
        {({ data, loading, error }) => {
            if (loading) return <Loading />
            if (error) return <p> item not found please delete</p>
            let content
            console.log("reorderable data",data)
            switch (data.type) {
                case "query":
                    content = <QuerySection record={data} resource="featured" itemStyle={itemStyle} />
                    break;
                case "items":
                    content = <ItemsSection record={data} resource="featured" itemStyle={itemStyle} />
                    break;
                default:
                    content = <div>section not found please delete</div>
                    break;
            }
            return <div>
                {content}
            </div>
        }}
    </Query>

} 