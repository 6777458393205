import React from "react";
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Edit,
  LongTextInput,
  Create,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  NumberInput,
  BooleanInput,
} from "react-admin";
import StatusInput from "./Input/StatusInput";
import { TextField, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ShareAndWinInput } from "./Input/ShareAndWin";
import PermissionsInput from "./Input/PermissionsInput";
//CSS
let hide = {
    display: "none",
  },
  center = {
    textAlign: "center",
  };

const CustomToolBar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Continue" />
    </Toolbar>
  );
};

const AddTracksTitle = () => <span>Add Track</span>;

const lastPage = () => {
  return window.history.back();
};

export const TracksCreate = (props) => {
  console.log("PROPS RECIEVED IN TRACKS CREATE", props);

  /**creating an alternate route depending on if a state has been sent from albums addtrack button */
  let presentState = props.location.state; // setting the state data to presentState

  console.log("STATE CARRIED ALONG TO TRACKSCREATE", presentState);

  return presentState !== undefined ? ( //TERNARY OPERATOR DECIDING WHICH COMPONENT TO RENDER
    <div>
      <p>Artwork</p>
      <img
        src={presentState.record.artwork}
        width="150px"
        alt={`linked to ${presentState.record.artwork}`}
      />
      <Create title={<AddTracksTitle />} {...props}>
        <SimpleForm toolbar={<CustomToolBar />}>
          <TextInput
            disabled
            style={hide}
            source="artwork"
            name="artwork"
            label="Artwork"
            defaultValue={presentState.record.artwork}
          />

          <TextInput
            disabled
            style={hide}
            optionText="Album"
            source="album"
            reference="albums"
            defaultValue={presentState.record.id}
          />

          <TextInput
            disabled
            style={hide}
            optionText="uid"
            source="uid"
            reference="uid"
            defaultValue={presentState.record.uid}
          />

          <TextInput
            disabled
            style={hide}
            source="cp"
            reference="cp"
            defaultValue={presentState.record.cp}
          />

          <TextInput
            disabled
            style={hide}
            source="artist"
            reference="artist"
            defaultValue={presentState.record.artist}
          />

          <TextInput
            disabled
            source="artist_name"
            reference="artist_name"
            defaultValue={presentState.record.artist_name}
          />

          <TextInput
            disabled
            style={hide}
            source="album_name"
            reference="album_name"
            defaultValue={presentState.record.name}
          />

          <TextInput
            disabled
            style={hide}
            source="releasedate"
            reference="releasedate"
            defaultValue={presentState.record.releasedate}
          />

          <TextInput disabled style={hide} source="duration" />

          {/* <ReferenceInput disabled source="artist" reference="artists">
                            <SelectInput optionText="name"  value={presentState.props.record.artist_name}/>
                        </ReferenceInput> */}
          <TextInput source="trackName" label="Track name" />
          <ReferenceInput source="genre" reference="genres">
            <SelectInput optionText="name" />
          </ReferenceInput>

          <TextField source="description" label="Description" />

          <StatusInput />

          <SelectArrayInput
            label="Mood"
            source="track_tags"
            choices={[
              { id: "Love Struck", name: "Love" },
              { id: "Motivated", name: "Motivated" },
              { id: "Work", name: "Work" },
              { id: "Sad", name: "Sad" },
              { id: "Road Trips", name: "Road Trips" },
              { id: "Family", name: "Family" },
              { id: "Workout", name: "Workout" },
              { id: "Fired Up", name: "Fired up" },
              { id: "Insomia", name: "Insomia" },
              { id: "Chilled", name: "Chilled" },
              { id: "Lazy", name: "Lazy" },
              { id: "Celebrations", name: "Celebrations" },
              { id: "Study", name: "Study" },
            ]}
          />
          <PermissionsInput />
          <NumberInput source="album_index" label="Album Index" />
          <BooleanInput source="isPromo" label="Share and Win Eligible" />
          <ShareAndWinInput />
        </SimpleForm>
      </Create>
    </div>
  ) : (
    <div style={center}>
      <h1>You have to add a track through an album</h1>
      <Button
        size="large"
        color="primary"
        component={Link}
        to={{
          pathname: "/albums",
        }}
      >
        Add track
      </Button>
      <Button color="primary" onClick={lastPage}>
        Go Back
      </Button>
    </div>
  );
};

const UsersTitle = ({ record }) => {
  return <span>Users {record ? `"${record.title}"` : ""}</span>;
};

export const UsersEdit = (props) => (
  <Edit title={<UsersTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="userID" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" />
      <TextInput source="location" />
      <TextInput source="age" />
      <TextInput source="gender" />
      <TextInput source="provider" />
    </SimpleForm>
  </Edit>
);

export const UsersCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="userID" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" />
      <TextInput source="location" />
      <TextInput source="age" />
      <TextInput source="gender" />
      <TextInput source="provider" />
    </SimpleForm>
  </Create>
);

const GenresTitle = ({ record }) => {
  return <span>Genres {record ? `"${record.title}"` : ""}</span>;
};

export const GenresEdit = (props) => (
  <Edit title={<GenresTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="name" />
      <LongTextInput source="description" />
    </SimpleForm>
  </Edit>
);

export const GenresCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="name" />
      <LongTextInput source="description" />
    </SimpleForm>
  </Create>
);

export const TracksAdder = (props) => {
  // component for adding tracks to albums
  console.log("PROPS RECIEVED IN TRACKSADDER IN EDIT ", props);
  return (
    <React.Fragment>
      <Create {...props}></Create>
    </React.Fragment>
  );
};
