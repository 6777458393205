import React from 'react';
import { Query, Loading, ImageField, Mutation, Button, EditButton, TextField as RATextField } from 'react-admin';
import Reorderable from '../../Input/Reorderable';
import { Typography, Grid } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
// import TextField from '@material-ui/core/TextField';
import Save from '@material-ui/icons/Save'

const ApproveButton = ({ record, resource, updatedTitle, items, done }) => {
    const { title, ...rest } = record;
    return <Mutation
        type="UPDATE"
        resource={resource}
        payload={{ id: record.id, data: { ...record, title: updatedTitle, items: items.map((v) => v.id) }, previousData: rest }}
        options={{
            undoable: false,
            onSuccess: {
                notification: { body: `${record.name} list has been updated`, level: "success" },
                refresh: false
            },
            onError: {
                notification: { body: `Error: ${record.name} list was not updated`, level: "warning" },
                refresh: false
            },
            refresh: false,
            callback: (props) => {
                if (!props.error) {
                    done(items)
                }
            }
        }}
    >
        {approve => <Button label={`Save changes to ${record.name} section`} onClick={approve}>
            <Save />
        </Button>}
    </Mutation>
}

const arrayEqual = (src, compare) => {
    // if the other array is a falsy value, return
    if (!compare)
        return false;

    // compare lengths - can save a lot of time 
    if (src.length !== compare.length)
        return false;

    for (var i = 0, l = src.length; i < l; i++) {
        // Check if we have nested arrays
        if (src[i] instanceof Array && compare[i] instanceof Array) {
            // recurse into the nested arrays
            if (!src[i].equals(compare[i]))
                return false;
        }
        else if (src[i] !== compare[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

const ItemsOrderable = ({ data, loading, error, style, record, resource }) => {
    const [items, setItems] = React.useState({ last: data.map(v => v.id), updated: data.map(v => v.id) })
    const [title,] = React.useState(record.title)

    return <Grid container direction="column">
        <Grid item style={{ backgroundColor: "#000", borderRadius: 10, padding: 10 }}>
            <Reorderable
                onChange={(updated) => setItems({ last: items.last, updated })}
                choices={data}
                renderItem={(item) => {
                    return (
                        <Grid container direction="column" justify="center" alignItems="center">
                            {item.pictures ?
                                <ImageField source="pictures.sizes.2.link" title="title" record={item} style={{
                                    height: 200,
                                    width: 180,
                                    // marginLeft: 10, 
                                    ...style
                                }} /> :
                                <ImageField source="artwork" title="title" record={item} style={{
                                    height: 200,
                                    width: 180,
                                    // marginLeft: 10, 
                                    ...style
                                }} />}
                            <RATextField source="name" record={item} />
                        </Grid>)
                }} />
        </Grid>
        {<Grow in={!arrayEqual(items.last, items.updated) || !arrayEqual([title], [record.title])}>
            <Grid style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} direction="row" container justify="flex-start" alignItems="center">
                <Typography color="textSecondary" variant="subheading" >This section has changed</Typography>
                <ApproveButton record={record} resource={resource}
                    items={items.updated}
                    updatedTitle={title}
                    done={(updated) => {
                        setItems({ last: updated, updated })
                        // setTitle(title)
                    }} />
            </Grid>
        </Grow>}
    </Grid>
}

export default ({
    record,
    resource,
    itemStyle,
    classes, ...rest }) => {
    const style = itemStyle ? itemStyle : {}
    const ids = record.items
    // console.log("query ids", ids);
    return <Query type="GET_MANY" resource={record.target} payload={{ ids }}>
        {(props) => {

            if (props.loading) return <Loading label={`${record.name} is being retrieved`} />
            if (props.error) {
                console.log("error from Item section", props, record);
                return <p>Item not found please delete</p>
                }
            return <Grid>
                <Grid item container xs={8}>
                    <Grid item xs={2}>
                        <Typography variant="headline" style={{
                            marginBottom: 10
                        }}>
                            {record.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <EditButton record={record} basePath="/featured" />
                    </Grid>
                </Grid>
                <ItemsOrderable style={style} record={record} resource={resource} {...props} />
            </Grid>
        }}
    </Query>
} 