import React, { } from 'react';
import {
    NumberField, BooleanField, Show, ReferenceField,
    TextField, Datagrid, List, SimpleShowLayout
} from 'react-admin'
import Pagination from '../../Pagination';

export const PlanList = props => (
    <List {...props} pagination={<Pagination />}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="title" />
            <TextField source="description" />
            <TextField source="paystackPlan" />
            <NumberField source="noUsers" />
            <TextField source="amount" />
            <NumberField source="period" />
            <BooleanField source="enabled" />
            <TextField source="role" />
            <TextField source="status" />
        </Datagrid>
    </List>
);

export const PlanShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="title" />
            <NumberField source="amount" />
            <NumberField source="noUsers" />
            <NumberField source="period" />
            <TextField source="description" />
            <BooleanField source="enabled" />
            <NumberField source="gp" label="Grace Days" />
            <TextField source="paystackPlan" />
            <ReferenceField source="role" reference="role">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
        </SimpleShowLayout>
    </Show>
);